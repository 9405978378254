import { appkey, restapilink, baseurl, yearnow, baseurlassets, basecolor1, basecolorred, appsetup } from './Autoload'; 
import axios from 'axios'; 
import React, { useEffect, useReducer, useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import DompetKuHomepage from './DompetKuHomepage';
import Header from './Header';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
// import DompetKuTransaksiList from './DompetKuTransaksiList';
const nl2br = require('react-nl2br');
const $ = require("jquery"); 
const Swal = require('sweetalert2'); 

function rupiah(angka) {  
  var bilangan = angka;         
  var	number_string = bilangan.toString(),  
      sisa 	= number_string.length % 3,
      rupiah 	= number_string.substr(0, sisa),
      ribuan 	= number_string.substr(sisa).match(/\d{3}/g);
  if (ribuan) {
      var separator = sisa ? '.' : '';
      rupiah += separator + ribuan.join('.');
  } 
  return rupiah;
}
function rupiah2(angka) {  
  var bilangan = angka;         
  var	number_string = bilangan.toString(),  
      sisa 	= number_string.length % 3,
      rupiah 	= number_string.substr(0, sisa),
      ribuan 	= number_string.substr(sisa).match(/\d{3}/g);
  if (ribuan) {
      var separator = sisa ? ',' : '';
      rupiah += separator + ribuan.join(',');
  } 
  return rupiah;
}
function getDaysInMonth(year, month) {    
  return new Date(year, month, 0).getDate();  
}

function DompetKu(props) {
  // const [ReloadDompetkuHomepage0, ReloadDompetkuHomepage] = useReducer(x => x + 1, 0);
  const {otherpage} = useParams();
  const token = localStorage.getItem('token');
  // if(!token) {
  //   localStorage.removeItem('token'); 
  //   document.location.href=baseurl+'login/'; 
  //   return false;
  // }
  const navigate = useNavigate();
  const [chartOptions, setChartOptions] = useState({
    chart: { type: 'pie'},
    title: { text: 'Portofolio DompetKu'},
    xAxis: { categories: []},
    series: [{name: 'Saldo',data: [0]}]
  });

  const [ceksesilogin2, setceksesilogin2] = useState(0);
  const [PenggunaPopupIntro3, setPenggunaPopupIntro3] = useState('no');
  const [loadpagedompetku, setloadpagedompetku] = useState(0);
  
  const [saldoSaya, setSaldoSaya] = useState('');
  const [jumlahDompetSaya, setJumlahDompetSaya] = useState('');
  const [datadompetdipilihsaya, setdatadompetdipilihsaya] = useState([]);
  const [iddompetkudompetdipilihsaya, setiddompetkudompetdipilihsaya] = useState([]);
  const [datasaldodompetdipilihsaya, setdatasaldodompetdipilihsaya] = useState([]);
  const [datadompetsaya, setdatadompetsaya] = useState([]);
  const [judulDompetSayaAdd, setJudulDompetSayaAdd] = useState('');
  const [datakattransaksipengeluaran, setdatakattransaksipengeluaran] = useState([]);
  const [datakattransaksipemasukan, setdatakattransaksipemasukan] = useState([]);
  const [datakattransaksi, setdatakattransaksi] = useState([]);
  const [datalistperiode, setdatalistperiode] = useState([]);
  const [periodetransaksiactive, setperiodetransaksiactive] = useState('');
  // const [periodetransaksiactive2, setperiodetransaksiactive2] = useState('');
  const [datatransaksiterakhir, setdatatransaksiterakhir] = useState([]);
  const [jumlahdatatransaksiterakhir, setjumlahdatatransaksiterakhir] = useState(0);

  const [lappemasukanhariini, setlappemasukanhariini] = useState('');
  const [lappemasukanbulanini, setlappemasukanbulanini] = useState('');
  const [lappemasukantahunini, setlappemasukantahunini] = useState('');
  const [lappengeluaranhariini, setlappengeluaranhariini] = useState('');
  const [lappengeluaranbulanini, setlappengeluaranbulanini] = useState('');
  const [lappengeluarantahunini, setlappengeluarantahunini] = useState('');
  // Header Dompetku
  const [jumlah, setjumlah] = useState('');
  const [kategori, setkategori] = useState('');
  const [tanggal, settanggal] = useState('');
  const [dompet, setdompet] = useState('');
  const [catatan, setcatatan] = useState('');
  const [judulProjek, setJudulProjek] = useState('');
  const [projekShareOrNo, setProjekShareOrNo] = useState('');
  const [projekSharing, setProjekSharing] = useState('');
  const [projekSharingMe, setProjekSharingMe] = useState('');
  const [projekSharingPermission, setProjekSharingPermission] = useState('');
  const [projekSharingEmail, setProjekSharingEmail] = useState('');
  const [projekSharingEmailJumlah, setProjekSharingEmailJumlah] = useState('');
  const [ketersediaanProjek, setKetersediaanProjek] = useState('');
  const [formjuduledit, setformjuduledit] = useState('');
  const [formdeskripsiedit, setformdeskripsiedit] = useState('');
  // Page Transaksi
  const [searchTransaksi, setSearchTransaksi] = useState('');
  const [jumlahlisttransaksi, setjumlahlisttransaksi] = useState(0);
  // End Page Transaksi
  
  // Page Lainnya
  // End Page Lainnya

  // Page Target Belanja
  const [searchTargetBelanja, setSearchTargetBelanja] = useState('');
  const [statusTargetBelanja, setStatusTargetBelanja] = useState('process');
  const [listtargetbelanja, setlisttargetbelanja] = useState([]);
  const [loaddatatargetbelanja, setloaddatatargetbelanja] = useState(1);
  const [jumlahlisttargetbelanja, setjumlahlisttargetbelanja] = useState(0);
  const [totalhargaprocess, settotalhargaprocess] = useState(0);
  const [totalhargacompleted, settotalhargacompleted] = useState(0);
  const [totalhargaall, settotalhargaall] = useState(0);
  
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 10; 
  const handlePageClick = (e) => {
    const newOffset = (e.selected * itemsPerPage) % listtargetbelanja.length;
    setItemOffset(newOffset);
  }
  // End Page Target Belanja

  // const [dateinsertedtugas, setdateinsertedtugas] = useState('0');
  // const [dateupdatedtugas, setdateupdatedtugas] = useState('0');
  // const [datecompletedtugas, setdatecompletedtugas] = useState('0');

  const getCekLogin2 = async () => {
    await axios.post(restapilink+"api/auth/ceklogin", {
      key:appkey,
      token:token
    }).then((response) => {
      setceksesilogin2(response.data.ceklogin)
      if(response.data.ceklogin<=0) {
        localStorage.removeItem('token'); 
        // document.location.href=baseurl+'login/'; 
        return false;
      }
      if(response.data.ceklogin == 0) {}
      else {
        if(response.data.datapengguna.pengguna_popupintro2) {
          setPenggunaPopupIntro3(response.data.datapengguna.pengguna_popupintro3);
        }
      }
    });
  }
  const cekDompetSaya = async() => {
    try {
      let response = await axios.post(restapilink+"api/dompetku/cekdompetsaya", {
        key:appkey,
        token: token
      });
      setdompet(response.data.data.id_dompetku);
      setdatadompetdipilihsaya(response.data.datadompetdipilihsaya.dompetku_nama);
      setiddompetkudompetdipilihsaya(response.data.datadompetdipilihsaya.id_dompetku);
      // setdompet(response.data.datadompetdipilihsaya.id_dompetku);
      setdatasaldodompetdipilihsaya(rupiah2(response.data.datadompetdipilihsaya.dompetku_saldo));
      setdatadompetsaya(response.data.datadompetsaya);
      setJumlahDompetSaya(response.data.jumlahall);

      if(response.data.datadompetdipilihsaya.dompetku_saldo<0) {
        setSaldoSaya("-"+rupiah2(Math.abs(response.data.datadompetdipilihsaya.dompetku_saldo)));
      }
      else if(response.data.datadompetdipilihsaya.dompetku_saldo==0) {
        setSaldoSaya(response.data.datadompetdipilihsaya.dompetku_saldo);
      }
      else if(response.data.datadompetdipilihsaya.dompetku_saldo>0) {
        setSaldoSaya(rupiah2(response.data.datadompetdipilihsaya.dompetku_saldo));
      }
      
      setdatakattransaksipengeluaran(response.data.datakattransaksipengeluaran);
      setdatakattransaksipemasukan(response.data.datakattransaksipemasukan);
      setdatakattransaksi(response.data.datakattransaksi);
    }
    catch(e) {
      // console.log(e);
    }
       
  }
  const cekTransaksiTerakhir = async() => {
    try {
      let response = await axios.post(restapilink+"api/dompetku/cektransaksiterakhir", {
        key:appkey,
        token: token,
        iddompet:iddompetkudompetdipilihsaya
      });
      setdatatransaksiterakhir(response.data.data);
      setjumlahdatatransaksiterakhir(response.data.jumlah);
    }
    catch(e) {
      // console.log(e);
    }
  }
  const cekListPeriodeTransaksi = async() => {
    try {
      let response = await axios.post(restapilink+"api/dompetku/ceklistperiode", {
        key:appkey, 
        token: token,
        dompet: iddompetkudompetdipilihsaya,
        periode: periodetransaksiactive
      }); 
      setdatalistperiode(response.data.data);
      setperiodetransaksiactive(response.data.transaksi_periodeawal);
      // setperiodetransaksiactive2(response.data.transaksi_periodeawal2);
    }
    catch(e) {
      // console.log(e);
    }
  }

  
  const cekLaporanKeuangan = async() => {
    // console.log(periodetransaksiactive);
    try {
      let response = await axios.post(restapilink+"api/dompetku/ceklaporankeuangan", {
        key:appkey,
        token: token,
        iddompet:iddompetkudompetdipilihsaya,
        periode: periodetransaksiactive
      });
      setlappemasukanhariini(response.data.pemasukanhariini);
      setlappemasukanbulanini(response.data.pemasukanbulanini);
      // console.log(response.data.pemasukanbulanini)
      setlappemasukantahunini(response.data.pemasukantahunini);
      setlappengeluaranhariini(response.data.pengeluaranhariini);
      setlappengeluaranbulanini(response.data.pengeluaranbulanini);
      // console.log(response.data.pengeluaranbulanini)
      setlappengeluarantahunini(response.data.pengeluarantahunini);
    }
    catch(e) {
      // console.log(e);
    }
  }


  const [datalisttransaksitest, setdatalisttransaksitest] = useState('');
  const cekListTransaksi = async() => {
    $(".loading_riplabs").css('display','block');
    await axios.post(restapilink+"api/dompetku/free", {
      key:appkey,
      token:token,
      dompet: iddompetkudompetdipilihsaya,
      periode: periodetransaksiactive,
      search: searchTransaksi
    }).then((response) => {
      setdatalisttransaksitest(response.data.data);
    });
  }

  const cekListTargetBelanja = async() => {
    $(".loading_riplabs").css('display','block');
    await axios.post(restapilink+"api/dompetku/listtargetbelanja", {
        key:appkey,
        token:token,
        statustarget: statusTargetBelanja,
        search: searchTargetBelanja
      }).then((response) => {
        $(".loading_riplabs").css('display','none'); 
        setlisttargetbelanja(response.data.data); 
        setjumlahlisttargetbelanja(response.data.jumlah);
        if(response.data.totalhargaprocess>0) {
          settotalhargaprocess(response.data.totalhargaprocess);
        }
        if(response.data.totalhargacompleted>0) {
          settotalhargacompleted(response.data.totalhargacompleted);
        }
        if(response.data.totalhargaall>0) {
          settotalhargaall(response.data.totalhargaall);
        }
        if(response.data.jumlah==0) {setloaddatatargetbelanja(0);}
        else {setloaddatatargetbelanja(1);} 
        if(statusTargetBelanja=="all") {
          $(".subtitletargetbelanja").html("Semua Target Belanja Saya")
        }
        else if(statusTargetBelanja=="process") {
          $(".subtitletargetbelanja").html("Target Belanja Dalam Proses")
        }
        else if(statusTargetBelanja=="completed") {
          $(".subtitletargetbelanja").html("Target Belanja Tercapai")
        }
    });
  }

  const tambahDompetSaya = async (e) => {
    e.preventDefault();
    Swal.fire({
      title: 'Oops...',
      html: 'Untuk saat ini, fitur tambah Dompet masih belum bisa digunakan <i class="fa fa-smile-beam"></i>',
      icon: 'info',
      confirmButtonColor: basecolor1
    });
    // $(".loading_riplabs").css('display','block');
    // const formData = new FormData();
    // formData.append('key',appkey);
    // formData.append('token',token);
    // formData.append('judulprojektugas',judulProjekTugasAdd);
    // await axios.post(restapilink+"api/dompetku/tambahprojektugasku", formData)
    // .then((response) => {
    //   $(".loading_riplabs").css('display','none');
    //   if(response.data.status=="error") {
    //     Swal.fire({
    //       title: 'Oops...',
    //       html: response.data.message + ' <i class="fa fa-smile-beam"></i>',
    //       icon: 'error',
    //       confirmButtonColor: basecolor1
    //     });
    //     return false;
    //   }
    //   else {
    //     $(".judulprojektugas").val('');
    //     setJudulProjekTugasAdd('');
    //     setTimeout(function(){
    //       getProjekTugasKu();
    //       getProjekTugasKuSharing();
    //       getTugasKu();
    //       Swal.fire({
    //         title: 'Berhasil',
    //         html: 'Projek Tugas baru sudah ditambahkan <i className="fa fa-smile-beam"></i>',
    //         icon: 'success',
    //         confirmButtonColor: basecolor1
    //       });
    //     }, 1000);
    //   }
    // });
  }

  // Header Dompetku
  const tambahKategoriTransaksiHandler = async () => {
    var judul = $("#forminputkategoritransaksiadd").val();
    var tipe = $("#formselecttipetrasaksiadd").find('option:selected').attr("value");
    if(judul.trim()=="" || tipe.trim()=="") {
      Swal.fire({
        title: 'Oops...',
        html: 'Jangan kosongkan Judul dan Tipe Kategori ya <i class="fa fa-smile-beam"></i>',
        icon: 'info',
        confirmButtonColor: basecolor1
      });
    }
    else {
      $(".formtambahkategoritransaksi button").attr("disabled","disabled");
      $(".loading_riplabs").css('display','block');
      try {
        let response = await axios.post(restapilink+"api/dompetku/crudkategoritransaksi", {
          key: appkey,
          token: token,
          kode: 'create',
          judul: judul,
          tipe: tipe
        });
        if(response.data.status=="error") {
          $(".formtambahkategoritransaksi button").removeAttr('disabled');
          $(".loading_riplabs").css('display','none');
          Swal.fire({
            title: 'Oops...',
            html: response.data.message + ' <i class="fa fa-smile-beam"></i>',
            icon: 'error',
            confirmButtonColor: basecolor1
          });
          return false;
        }
        else {
          setTimeout(function(){
            $(".formtambahkategoritransaksi button").removeAttr('disabled');
            $(".loading_riplabs").css('display','none');
            $("#forminputkategoritransaksiadd").val('');
            cekDompetSaya();
            Swal.fire({
              title: 'Berhasil',
              html: 'Kategori Transaksi baru sudah ditambahkan <i className="fa fa-smile-beam"></i>',
              icon: 'success',
              confirmButtonColor: basecolor1
            });
          }, 1000);
        }
      }
      catch(e) {
        // console.log(e);
      }
    }
  }
  const perbaruiKategoriTransaksiHandler = async (e) => {
    var judul = $(".formkategorijuduledit"+e).val();
    var tipe = $(".formkategoritipeedit"+e).find('option:selected').attr("value");
    if(judul.trim()=="" || tipe.trim()=="") {
      Swal.fire({
        title: 'Oops...',
        html: 'Jangan kosongkan Judul dan Tipe Kategori ya <i class="fa fa-smile-beam"></i>',
        icon: 'info',
        confirmButtonColor: basecolor1
      });
    }
    else {
      $(".perbaruikategoritransaksi"+e+" button").attr("disabled","disabled");
      $(".loading_riplabs").css('display','block');
      try {
        let response = await axios.post(restapilink+"api/dompetku/crudkategoritransaksi", {
          key: appkey,
          token: token,
          kode: 'update',
          id: e,
          judul: judul,
          tipe: tipe
        });
        if(response.data.status=="error") {
          $(".perbaruikategoritransaksi"+e+" button").removeAttr('disabled');
          $(".loading_riplabs").css('display','none');
          Swal.fire({
            title: 'Oops...',
            html: response.data.message + ' <i class="fa fa-smile-beam"></i>',
            icon: 'error',
            confirmButtonColor: basecolor1
          });
          return false;
        }
        else {
          setTimeout(function(){
            $(".perbaruikategoritransaksi"+e+" button").removeAttr('disabled');
            $(".loading_riplabs").css('display','none');
            $(".formkategorijuduledit"+e).val('');
            cekDompetSaya(); 
            cekTransaksiTerakhir(); 
            Swal.fire({
              title: 'Berhasil',
              html: 'Kategori Transaksi sudah diperbarui <i className="fa fa-smile-beam"></i>',
              icon: 'success',
              confirmButtonColor: basecolor1
            });
          }, 500);
        }
      }
      catch(e) {
        // console.log(e);
      }
    }
  }
  const hapusKategoriTransaksiHandler = async (e) => {
    Swal.fire({
      title: 'Konfirmasi',
      html: 'Apakah kamu yakin ingin menghapus Kategori Transaksi ini?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: basecolor1,
      cancelButtonColor: basecolorred,
      confirmButtonText: 'Ya, Lanjutkan',
      cancelButtonText: 'Tidak'
    }).then((result) => {
      if (result.value) {
        $(".loading_riplabs").css('display','block');
        try {
          axios.post(restapilink+"api/dompetku/crudkategoritransaksi", {
            key:appkey,
            token: token,
            kode: 'delete',
            id:e
          });
          setTimeout(function(){
            $(".loading_riplabs").css('display','none');
            cekDompetSaya();
            Swal.fire({
              title: 'Berhasil',
              html: 'Kategori Transaksi sudah dihapus <i className="fa fa-smile-beam"></i>',
              icon: 'success',
              confirmButtonColor: basecolor1
            });
          }, 500);
        }
        catch(e) {
          // console.log(e);
        }
      }
    });
  }
  const tambahTransaksi = async (e) => {
    e.preventDefault();
    var jumlah2 = $("#tambahtransaksi #jumlah").val();
    var kategori2 = $("#tambahtransaksi #kategori").find('option:selected').attr("value");
    var tanggal2 = $("#tambahtransaksi #tanggal").val();
    // var dompet2 = $("#tambahtransaksi #dompet").find('option:selected').attr("value");
    var dompet2 = iddompetkudompetdipilihsaya;
    var catatan2 = $("#tambahtransaksi #catatan").val(); 
    // if ($("#masukkankerangkumantransaksi").is(':checked')){
    //   var rangkumantransaksi = "yes";
    // }
    // else {
    //   var rangkumantransaksi = "no";
    // }
    var rangkumantransaksi = "yes";
    var cekformattanggal = 0;
    var newtanggal22 = "";
    var newbulan22 = "";
    // var newtahun22 = "";
    // Validasi Tanggal
    var pecahtanggal = tanggal2.split(" ");
    // Konversi hari
    if(pecahtanggal[0]=="Sunday,") {}
    else if(pecahtanggal[0]=="Minggu,") {}
    else if(pecahtanggal[0]=="Monday,") {}
    else if(pecahtanggal[0]=="Senin,") {}
    else if(pecahtanggal[0]=="Tuesday,") {}
    else if(pecahtanggal[0]=="Selasa,") {}
    else if(pecahtanggal[0]=="Wednesday,") {}
    else if(pecahtanggal[0]=="Rabu,") {}
    else if(pecahtanggal[0]=="Thursday,") {}
    else if(pecahtanggal[0]=="Kamis,") {}
    else if(pecahtanggal[0]=="Friday,") {}
    else if(pecahtanggal[0]=="Jumat,") {}
    else if(pecahtanggal[0]=="Jumat,") {}
    else if(pecahtanggal[0]=="Saturday,") {}
    else if(pecahtanggal[0]=="Sabtu,") {}
    else {cekformattanggal+=1;} 
    // Konversi hari 
    if(pecahtanggal[1]=="01") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="02") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="03") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="04") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="05") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="06") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="07") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="08") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="09") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="2") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="3") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="4") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="5") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="6") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="7") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="8") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="9") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="10") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="11") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="12") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="13") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="14") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="15") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="16") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="17") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="18") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="19") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="20") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="21") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="22") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="23") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="24") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="25") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="26") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="27") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="28") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="29") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="30") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="31") {newtanggal22+=pecahtanggal[1];}
    else {cekformattanggal+=1;}
    // Konversi bulan
    if(pecahtanggal[2]=="January") {newbulan22+="01";}
    else if(pecahtanggal[2]=="Januari") {newbulan22+="01";}
    else if(pecahtanggal[2]=="February") {newbulan22+="02";}
    else if(pecahtanggal[2]=="Februari") {newbulan22+="02";}
    else if(pecahtanggal[2]=="March") {newbulan22+="03";}
    else if(pecahtanggal[2]=="Maret") {newbulan22+="03";}
    else if(pecahtanggal[2]=="April") {newbulan22+="04";}
    else if(pecahtanggal[2]=="April") {newbulan22+="04";}
    else if(pecahtanggal[2]=="May") {newbulan22+="05";}
    else if(pecahtanggal[2]=="Mei") {newbulan22+="05";}
    else if(pecahtanggal[2]=="June") {newbulan22+="06";}
    else if(pecahtanggal[2]=="Juni") {newbulan22+="06";}
    else if(pecahtanggal[2]=="July") {newbulan22+="07";}
    else if(pecahtanggal[2]=="Juli") {newbulan22+="07";}
    else if(pecahtanggal[2]=="August") {newbulan22+="08";}
    else if(pecahtanggal[2]=="Agustus") {newbulan22+="08";}
    else if(pecahtanggal[2]=="September") {newbulan22+="09";}
    else if(pecahtanggal[2]=="September") {newbulan22+="09";}
    else if(pecahtanggal[2]=="October") {newbulan22+="10";}
    else if(pecahtanggal[2]=="Oktober") {newbulan22+="10";}
    else if(pecahtanggal[2]=="November") {newbulan22+="11";}
    else if(pecahtanggal[2]=="November") {newbulan22+="11";}
    else if(pecahtanggal[2]=="December") {newbulan22+="12";}
    else if(pecahtanggal[2]=="Desember") {newbulan22+="12";}
    else {cekformattanggal+=1;}
    // Konversi tahun
    // if(pecahtanggal[3]=="Sunday,") {}
    // else {cekformattanggal+=1;}
    if(pecahtanggal[0].trim()=="" || pecahtanggal[1].trim()=="" || pecahtanggal[2].trim()=="" || pecahtanggal[3].trim()=="") {
      Swal.fire({
        title: 'Oops...',
        html: 'Jangan kosongkan Tanggal Transaksi ya <i class="fa fa-smile-beam"></i>',
        icon: 'info',
        confirmButtonColor: basecolor1
      });
      return false;
    }
    else {
      if(cekformattanggal>0) {
        Swal.fire({
          title: 'Oops...',
          html: 'Silahkan masukkan format Tanggal dengan benar ya <i class="fa fa-smile-beam"></i>',
          icon: 'info',
          confirmButtonColor: basecolor1
        });
        return false;
      }
      else { 

        if(tanggal2.trim()!=="") {
          let pecahdate = tanggal2.split(" ");
          if(pecahdate[0]=="" || pecahdate[1]=="" || pecahdate[2]=="" || pecahdate[3]=="") {
            // Notif tanggal ga bener
            // var tanggal2 = '';
            // settanggal('');
            // $("#tanggal").val('');
            return false;
          }
          else {
            var dayname0 = pecahdate[0];
            var dayname = "";
            var monthname = "";
            var monthname0 = pecahdate[2];
            // konversi hari
            switch (dayname0) {
              case "Sunday,": dayname = "Minggu,"; break;
              case "Minggu,": dayname = "Minggu,"; break;
              case "Monday,": dayname = "Senin,"; break;
              case "Senin,": dayname = "Senin,"; break;
              case "Tuesday,": dayname = "Selasa,"; break;
              case "Selasa,": dayname = "Selasa,"; break;
              case "Wednesday,": dayname = "Rabu,"; break;
              case "Rabu,": dayname = "Rabu,"; break;
              case "Thursday,": dayname = "Kamis,"; break;
              case "Kamis,": dayname = "Kamis,"; break;
              case "Friday,": dayname = "Jumat,"; break;
              case "Jumat,": dayname = "Jumat,"; break;
              case "Saturday,": dayname = "Sabtu,"; break;
              case "Sabtu,": dayname = "Sabtu,"; break;
              default: dayname = "";settanggal('');$("#tanggal").val('');break;
            }
            // konversi bulan
            switch (monthname0) {
              case "January": monthname = "Januari"; break;
              case "Januari": monthname = "Januari"; break;
              case "01": monthname = "Januari"; break;
              case "February": monthname = "Februari"; break;
              case "Februari": monthname = "Februari"; break;
              case "02": monthname = "Februari"; break;
              case "March": monthname = "Maret"; break;
              case "Maret": monthname = "Maret"; break;
              case "03": monthname = "Maret"; break;
              case "April": monthname = "April"; break;
              case "April": monthname = "April"; break;
              case "04": monthname = "April"; break;
              case "May": monthname = "Mei"; break;
              case "Mei": monthname = "Mei"; break;
              case "05": monthname = "Mei"; break;
              case "June": monthname = "Juni"; break;
              case "Juni": monthname = "Juni"; break;
              case "06": monthname = "Juni"; break;
              case "July": monthname = "Juli"; break;
              case "Juli": monthname = "Juli"; break;
              case "07": monthname = "Juli"; break;
              case "August": monthname = "Agustus"; break;
              case "Agustus": monthname = "Agustus"; break;
              case "08": monthname = "Agustus"; break;
              case "September": monthname = "September"; break;
              case "September": monthname = "September"; break;
              case "09": monthname = "September"; break;
              case "October": monthname = "Oktober"; break;
              case "Oktober": monthname = "Oktober"; break;
              case "10": monthname = "Oktober"; break;
              case "November": monthname = "November"; break;
              case "November": monthname = "November"; break;
              case "11": monthname = "November"; break;
              case "December": monthname = "Desember"; break;
              case "Desember": monthname = "Desember"; break;
              case "12": monthname = "Desember"; break;
              default: monthname = "";settanggal('');$("#tanggal").val('');break;
            }
            var newdate = dayname + ' ' + pecahdate[1] + ' ' + monthname + ' ' + pecahdate[3];
            if(tanggal.trim()=="") {
              // $(".tanggal").val(newdate);
              // settanggal(newdate);
              var tanggal2 = newdate;
            }
          }
        }
        var newtanggalfortanggal2 = pecahtanggal[3]+"-"+newbulan22+"-"+newtanggal22;
        var newperiode = newbulan22+"/"+pecahtanggal[3]; 
        $("form#tambahtransaksi #submit").attr('disabled','disabled'); 
        $(".loading_riplabs").css('display','block');
        try {
          let response = await axios.post(restapilink+"api/dompetku/tambahtransaksi", {
            key: appkey,
            token: token,
            jumlah: jumlah2,
            kategori: kategori2,
            tanggal: tanggal2,
            tanggal2: newtanggalfortanggal2,
            periode: newperiode,
            dompet: dompet2,
            catatan: catatan2,
            rangkumantransaksi: rangkumantransaksi
          });
          if(response.data.status=="error") {
            $("form#tambahtransaksi #submit").removeAttr('disabled');
            $(".loading_riplabs").css('display','none');
            Swal.fire({
              title: 'Oops...',
              html: response.data.message + ' <i class="fa fa-smile-beam"></i>',
              icon: 'error',
              confirmButtonColor: basecolor1
            });
            return false;
          }
          else {
            $("form#tambahtransaksi #submit").removeAttr('disabled');
            $(".loading_riplabs").css('display','none');
            $(".modal .close").click();
            $("#jumlah").val('');
            $("#catatan").val('');
            setjumlah('');
            setcatatan('');
            Swal.fire({
              title: 'Berhasil',
              html: 'Transaksi baru sudah ditambahkan <i className="fa fa-smile-beam"></i>',
              icon: 'success',
              confirmButtonColor: basecolor1
            });
            setTimeout(function(){ 
              cekDompetSaya(); 
              // ReloadDompetkuHomepage();
              cekTransaksiTerakhir();
              cekLaporanKeuangan();
              cekListPeriodeTransaksi();
              cekListTransaksi();
            }, 500);
          }
        }
        catch(e) {
          // console.log(e);
        }
      }
    }
    // End Validasi Tanggal
  }
  const tambahkategoritransaksi = () => {
    $(".boxtambahkategoritransaksi").slideToggle();
  }
  const editTransaksi = async (e) => {
    e.preventDefault();
    var id_transaksi = $("#popupedittransaksi #id_transaksiedit").val();
    var jumlah2 = $("#popupedittransaksi #jumlahedit").val();
    var kategori2 = $("#popupedittransaksi #kategoriedit").find('option:selected').attr("value");
    var tanggal2 = $("#popupedittransaksi #tanggaledit").val();
    // var dompet2 = $("#popupedittransaksi #dompet").find('option:selected').attr("value");
    var dompet2 = iddompetkudompetdipilihsaya;
    var catatan2 = $("#popupedittransaksi #catatanedit").val(); 
    // if ($("#masukkankerangkumantransaksiedit").is(':checked')){ var rangkumantransaksi = "yes"; }
    // else { var rangkumantransaksi = "no"; }
    var rangkumantransaksi = "yes";
    var cekformattanggal = 0;
    var newtanggal22 = "";
    var newbulan22 = "";
    // var newtahun22 = "";
    // Validasi Tanggal
    var pecahtanggal = tanggal2.split(" ");
    // Konversi hari
    if(pecahtanggal[0]=="Sunday,") {}
    else if(pecahtanggal[0]=="Minggu,") {}
    else if(pecahtanggal[0]=="Monday,") {}
    else if(pecahtanggal[0]=="Senin,") {}
    else if(pecahtanggal[0]=="Tuesday,") {}
    else if(pecahtanggal[0]=="Selasa,") {}
    else if(pecahtanggal[0]=="Wednesday,") {}
    else if(pecahtanggal[0]=="Rabu,") {}
    else if(pecahtanggal[0]=="Thursday,") {}
    else if(pecahtanggal[0]=="Kamis,") {}
    else if(pecahtanggal[0]=="Friday,") {}
    else if(pecahtanggal[0]=="Jumat,") {}
    else if(pecahtanggal[0]=="Jumat,") {}
    else if(pecahtanggal[0]=="Saturday,") {}
    else if(pecahtanggal[0]=="Sabtu,") {}
    else {cekformattanggal+=1;} 
    // Konversi hari 
    if(pecahtanggal[1]=="01") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="02") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="03") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="04") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="05") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="06") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="07") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="08") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="09") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="2") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="3") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="4") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="5") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="6") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="7") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="8") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="9") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="10") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="11") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="12") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="13") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="14") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="15") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="16") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="17") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="18") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="19") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="20") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="21") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="22") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="23") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="24") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="25") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="26") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="27") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="28") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="29") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="30") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="31") {newtanggal22+=pecahtanggal[1];}
    else {cekformattanggal+=1;}
    // Konversi bulan
    if(pecahtanggal[2]=="January") {newbulan22+="01";}
    else if(pecahtanggal[2]=="Januari") {newbulan22+="01";}
    else if(pecahtanggal[2]=="February") {newbulan22+="02";}
    else if(pecahtanggal[2]=="Februari") {newbulan22+="02";}
    else if(pecahtanggal[2]=="March") {newbulan22+="03";}
    else if(pecahtanggal[2]=="Maret") {newbulan22+="03";}
    else if(pecahtanggal[2]=="April") {newbulan22+="04";}
    else if(pecahtanggal[2]=="April") {newbulan22+="04";}
    else if(pecahtanggal[2]=="May") {newbulan22+="05";}
    else if(pecahtanggal[2]=="Mei") {newbulan22+="05";}
    else if(pecahtanggal[2]=="June") {newbulan22+="06";}
    else if(pecahtanggal[2]=="Juni") {newbulan22+="06";}
    else if(pecahtanggal[2]=="July") {newbulan22+="07";}
    else if(pecahtanggal[2]=="Juli") {newbulan22+="07";}
    else if(pecahtanggal[2]=="August") {newbulan22+="08";}
    else if(pecahtanggal[2]=="Agustus") {newbulan22+="08";}
    else if(pecahtanggal[2]=="September") {newbulan22+="09";}
    else if(pecahtanggal[2]=="September") {newbulan22+="09";}
    else if(pecahtanggal[2]=="October") {newbulan22+="10";}
    else if(pecahtanggal[2]=="Oktober") {newbulan22+="10";}
    else if(pecahtanggal[2]=="November") {newbulan22+="11";}
    else if(pecahtanggal[2]=="November") {newbulan22+="11";}
    else if(pecahtanggal[2]=="December") {newbulan22+="12";}
    else if(pecahtanggal[2]=="Desember") {newbulan22+="12";}
    else {cekformattanggal+=1;}
    // Konversi tahun
    // if(pecahtanggal[3]=="Sunday,") {}
    // else {cekformattanggal+=1;}
    if(pecahtanggal[0].trim()=="" || pecahtanggal[1].trim()=="" || pecahtanggal[2].trim()=="" || pecahtanggal[3].trim()=="") {
      Swal.fire({
        title: 'Oops...',
        html: 'Jangan kosongkan Tanggal Transaksi ya <i class="fa fa-smile-beam"></i>',
        icon: 'info',
        confirmButtonColor: basecolor1
      });
      return false;
    }
    else {
      if(cekformattanggal>0) {
        Swal.fire({
          title: 'Oops...',
          html: 'Silahkan masukkan format Tanggal dengan benar ya <i class="fa fa-smile-beam"></i>',
          icon: 'info',
          confirmButtonColor: basecolor1
        });
        return false;
      }
      else { 
        if(tanggal2.trim()!=="") {
          let pecahdate = tanggal2.split(" ");
          if(pecahdate[0]=="" || pecahdate[1]=="" || pecahdate[2]=="" || pecahdate[3]=="") {
            // Notif tanggal ga bener
            // var tanggal2 = '';
            // settanggal('');
            // $("#tanggal").val('');
            return false;
          }
          else {
            var dayname0 = pecahdate[0];
            var dayname = "";
            var monthname = "";
            var monthname0 = pecahdate[2];
            // konversi hari
            switch (dayname0) {
              case "Sunday,": dayname = "Minggu,"; break;
              case "Minggu,": dayname = "Minggu,"; break;
              case "Monday,": dayname = "Senin,"; break;
              case "Senin,": dayname = "Senin,"; break;
              case "Tuesday,": dayname = "Selasa,"; break;
              case "Selasa,": dayname = "Selasa,"; break;
              case "Wednesday,": dayname = "Rabu,"; break;
              case "Rabu,": dayname = "Rabu,"; break;
              case "Thursday,": dayname = "Kamis,"; break;
              case "Kamis,": dayname = "Kamis,"; break;
              case "Friday,": dayname = "Jumat,"; break;
              case "Jumat,": dayname = "Jumat,"; break;
              case "Saturday,": dayname = "Sabtu,"; break;
              case "Sabtu,": dayname = "Sabtu,"; break;
              default: dayname = "";settanggal('');$("#tanggal").val('');break;
            }
            // konversi bulan
            switch (monthname0) {
              case "January": monthname = "Januari"; break;
              case "Januari": monthname = "Januari"; break;
              case "01": monthname = "Januari"; break;
              case "February": monthname = "Februari"; break;
              case "Februari": monthname = "Februari"; break;
              case "02": monthname = "Februari"; break;
              case "March": monthname = "Maret"; break;
              case "Maret": monthname = "Maret"; break;
              case "03": monthname = "Maret"; break;
              case "April": monthname = "April"; break;
              case "April": monthname = "April"; break;
              case "04": monthname = "April"; break;
              case "May": monthname = "Mei"; break;
              case "Mei": monthname = "Mei"; break;
              case "05": monthname = "Mei"; break;
              case "June": monthname = "Juni"; break;
              case "Juni": monthname = "Juni"; break;
              case "06": monthname = "Juni"; break;
              case "July": monthname = "Juli"; break;
              case "Juli": monthname = "Juli"; break;
              case "07": monthname = "Juli"; break;
              case "August": monthname = "Agustus"; break;
              case "Agustus": monthname = "Agustus"; break;
              case "08": monthname = "Agustus"; break;
              case "September": monthname = "September"; break;
              case "September": monthname = "September"; break;
              case "09": monthname = "September"; break;
              case "October": monthname = "Oktober"; break;
              case "Oktober": monthname = "Oktober"; break;
              case "10": monthname = "Oktober"; break;
              case "November": monthname = "November"; break;
              case "November": monthname = "November"; break;
              case "11": monthname = "November"; break;
              case "December": monthname = "Desember"; break;
              case "Desember": monthname = "Desember"; break;
              case "12": monthname = "Desember"; break;
              default: monthname = "";settanggal('');$("#tanggal").val('');break;
            }
            var newdate = dayname + ' ' + pecahdate[1] + ' ' + monthname + ' ' + pecahdate[3];
            if(tanggal.trim()=="") {
              // $(".tanggal").val(newdate);
              // settanggal(newdate);
              var tanggal2 = newdate;
            }
          }
        }
        var newtanggalfortanggal2 = pecahtanggal[3]+"-"+newbulan22+"-"+newtanggal22;
        var newperiode = newbulan22+"/"+pecahtanggal[3]; 
        $("form#edittransaksi #submit").attr('disabled','disabled'); 
        $(".loading_riplabs").css('display','block');
        try {
          let response = await axios.post(restapilink+"api/dompetku/edittransaksi", {
            key: appkey,
            token: token,
            id_transaksi: id_transaksi,
            jumlah: jumlah2,
            kategori: kategori2,
            tanggal: tanggal2,
            tanggal2: newtanggalfortanggal2,
            periode: newperiode,
            dompet: dompet2,
            catatan: catatan2,
            rangkumantransaksi: rangkumantransaksi
          });
          if(response.data.status=="error") {
            $("form#edittransaksi #submit").removeAttr('disabled');
            $(".loading_riplabs").css('display','none');
            Swal.fire({
              title: 'Oops...',
              html: response.data.message + ' <i class="fa fa-smile-beam"></i>',
              icon: 'error',
              confirmButtonColor: basecolor1
            });
            return false;
          }
          else {
            $("form#edittransaksi #submit").removeAttr('disabled');
            $(".loading_riplabs").css('display','none');
            $(".modal .close").click(); 
            Swal.fire({
              title: 'Berhasil',
              html: 'Transaksi sudah diperbarui <i className="fa fa-smile-beam"></i>',
              icon: 'success',
              confirmButtonColor: basecolor1
            });
            setTimeout(function(){
              cekDompetSaya();
              cekTransaksiTerakhir();
              cekLaporanKeuangan();
            }, 500);
          }
        }
        catch(e) {
          // console.log(e);
        }
      }
    }
    // End Validasi Tanggal
  }
  const hapusTransaksi = async (e) => {
    Swal.fire({
      title: 'Konfirmasi',
      html: 'Apakah kamu yakin ingin menghapus Transaksi ini?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: basecolor1,
      cancelButtonColor: basecolorred,
      confirmButtonText: 'Ya, Lanjutkan',
      cancelButtonText: 'Tidak'
    }).then((result) => {
      if (result.value) {
        $(".loading_riplabs").css('display','block');
        try {
          axios.post(restapilink+"api/dompetku/hapustransaksi", {
            key: appkey,
            token: token,
            dompet: iddompetkudompetdipilihsaya,
            id_transaksi: e
          });
          $(".loading_riplabs").css('display','none');
          $(".modal .close").click();
          Swal.fire({
            title: 'Berhasil',
            html: 'Transaksi sudah dihapus <i className="fa fa-smile-beam"></i>',
            icon: 'success',
            confirmButtonColor: basecolor1
          });
          setTimeout(function(){
            cekDompetSaya();
            cekTransaksiTerakhir();
            cekLaporanKeuangan();
            cekListPeriodeTransaksi();
            cekListTransaksi();
          }, 500);
        }
        catch(e) {
          // console.log(e);
        }
      }
    });
  }
  // End Header Dompetku


  // Page Transaksi 
  const searchTransaksiHandler = async (e) => {
    setSearchTransaksi(e);
  }
  const changeListPeriodeTransaksi = async (e) => {
    setperiodetransaksiactive(e); 
    $(".menulistperiode").removeClass(' active ');
    $(".menulistperiode"+e).addClass(' active ');
  }
  const showPopupEditTransaksi = async (id_transaksi) => {
    // Get data
    await axios.post(restapilink+"api/dompetku/transaksiid", {
      key:appkey,
      token:token,
      dompet:iddompetkudompetdipilihsaya,
      id_transaksi:id_transaksi
    }).then((response) => {
      // console.log(response.data);
      // Fill form using data from server 
      $("#popupedittransaksi .hapustransaksi").attr("id",response.data.data.id_transaksi);
      $("#popupedittransaksi #id_transaksiedit").val(response.data.data.id_transaksi);
      $("#popupedittransaksi #jumlahedit").val(rupiah2(response.data.data.transaksi_jumlah)); 
      $("#popupedittransaksi #tanggaledit").val(response.data.data.transaksi_tanggal.replaceAll("\\'","'"));
      $("#popupedittransaksi #catatanedit").val(response.data.data.transaksi_catatan.replaceAll("\\'","'"));
      var htmlkategoriedit = "";
      htmlkategoriedit += '<option value="'+response.data.data.id_transaksi_kategori+'">'+response.data.data.transaksi_kategori_judul+'</option>'; 
      htmlkategoriedit += '<optgroup label="Pengeluaran">';
        {
          datakattransaksipengeluaran.map((data, i) => {
            htmlkategoriedit += '<option key="'+i+'" tipe="outcome" value="'+data.id_kategori+'">'+data.kategori_judul+'</option>';
          })
        }
      htmlkategoriedit += '</optgroup>';
      htmlkategoriedit += '<optgroup label="Pemasukan">';
        {
          datakattransaksipemasukan.map((data, i) => {
            htmlkategoriedit += '<option key="'+i+'" tipe="income" value="'+data.id_kategori+'">'+data.kategori_judul+'</option>';
          })
        }
      htmlkategoriedit += '</optgroup>';
      $("#popupedittransaksi #kategoriedit").html(htmlkategoriedit);

      // if(response.data.data.transaksi_rangkuman=="yes") {
      //   $("#masukkankerangkumantransaksiedit").attr("checked","checked");
      // }
      // else {
      //   $("#masukkankerangkumantransaksiedit").removeAttr("checked");
      // }

    });
  }
  // End Page Transaksi 
  // Page Target Belanja 
  const searchTargetBelanjaHandler = async (e) => {
    setSearchTargetBelanja(e);
  }
  const statusTargetBelanjaHandler = async (e) => {
    setStatusTargetBelanja(e);
  }
  const showPopupEditTargetBelanja = async (id_target) => {
    // Get data
    await axios.post(restapilink+"api/dompetku/targetbelanjaid", {
      key:appkey,
      token:token,
      id_target:id_target
    }).then((response) => { 
      // Fill form using data from server  
      $("#popupedittargetbelanja .hapustargetbelanja").attr("id",response.data.data.id_target);
      $("#popupedittargetbelanja #id_targetedit").val(response.data.data.id_target);
      $("#popupedittargetbelanja #namatargetedit").val(response.data.data.target_nama); 
      $("#popupedittargetbelanja #jumlahbarangtargetedit").val(response.data.data.target_jumlah);
      $("#popupedittargetbelanja #totalhargatargetedit").val(rupiah2(response.data.data.target_harga)); 
      $("#popupedittargetbelanja #tanggaltargetedit").val(response.data.data.target_tanggal.replaceAll("\\'","'"));
    });
  }
  // Tambah
  const tambahTargetBelanja = async (e) => {
    e.preventDefault();
    var namatarget = $("#popuptambahtargetbelanja #namatarget").val();
    var jumlahbarangtarget = $("#popuptambahtargetbelanja #jumlahbarangtarget").val();
    var totalhargatarget = $("#popuptambahtargetbelanja #totalhargatarget").val();
    var tanggaltarget = $("#popuptambahtargetbelanja #tanggaltarget").val();

    var cekformattanggal = 0;
    var newtanggal22 = "";
    var newbulan22 = "";
    // var newtahun22 = "";
    // Validasi Tanggal
    var pecahtanggal = tanggaltarget.split(" ");
    // Konversi hari
    if(pecahtanggal[0]=="Sunday,") {}
    else if(pecahtanggal[0]=="Minggu,") {}
    else if(pecahtanggal[0]=="Monday,") {}
    else if(pecahtanggal[0]=="Senin,") {}
    else if(pecahtanggal[0]=="Tuesday,") {}
    else if(pecahtanggal[0]=="Selasa,") {}
    else if(pecahtanggal[0]=="Wednesday,") {}
    else if(pecahtanggal[0]=="Rabu,") {}
    else if(pecahtanggal[0]=="Thursday,") {}
    else if(pecahtanggal[0]=="Kamis,") {}
    else if(pecahtanggal[0]=="Friday,") {}
    else if(pecahtanggal[0]=="Jumat,") {}
    else if(pecahtanggal[0]=="Jumat,") {}
    else if(pecahtanggal[0]=="Saturday,") {}
    else if(pecahtanggal[0]=="Sabtu,") {}
    else {cekformattanggal+=1;} 
    // Konversi hari 
    if(pecahtanggal[1]=="01") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="02") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="03") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="04") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="05") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="06") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="07") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="08") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="09") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="2") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="3") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="4") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="5") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="6") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="7") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="8") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="9") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="10") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="11") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="12") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="13") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="14") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="15") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="16") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="17") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="18") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="19") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="20") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="21") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="22") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="23") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="24") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="25") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="26") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="27") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="28") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="29") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="30") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="31") {newtanggal22+=pecahtanggal[1];}
    else {cekformattanggal+=1;}
    // Konversi bulan
    if(pecahtanggal[2]=="January") {newbulan22+="01";}
    else if(pecahtanggal[2]=="Januari") {newbulan22+="01";}
    else if(pecahtanggal[2]=="February") {newbulan22+="02";}
    else if(pecahtanggal[2]=="Februari") {newbulan22+="02";}
    else if(pecahtanggal[2]=="March") {newbulan22+="03";}
    else if(pecahtanggal[2]=="Maret") {newbulan22+="03";}
    else if(pecahtanggal[2]=="April") {newbulan22+="04";}
    else if(pecahtanggal[2]=="April") {newbulan22+="04";}
    else if(pecahtanggal[2]=="May") {newbulan22+="05";}
    else if(pecahtanggal[2]=="Mei") {newbulan22+="05";}
    else if(pecahtanggal[2]=="June") {newbulan22+="06";}
    else if(pecahtanggal[2]=="Juni") {newbulan22+="06";}
    else if(pecahtanggal[2]=="July") {newbulan22+="07";}
    else if(pecahtanggal[2]=="Juli") {newbulan22+="07";}
    else if(pecahtanggal[2]=="August") {newbulan22+="08";}
    else if(pecahtanggal[2]=="Agustus") {newbulan22+="08";}
    else if(pecahtanggal[2]=="September") {newbulan22+="09";}
    else if(pecahtanggal[2]=="September") {newbulan22+="09";}
    else if(pecahtanggal[2]=="October") {newbulan22+="10";}
    else if(pecahtanggal[2]=="Oktober") {newbulan22+="10";}
    else if(pecahtanggal[2]=="November") {newbulan22+="11";}
    else if(pecahtanggal[2]=="November") {newbulan22+="11";}
    else if(pecahtanggal[2]=="December") {newbulan22+="12";}
    else if(pecahtanggal[2]=="Desember") {newbulan22+="12";}
    else {cekformattanggal+=1;}
    // Konversi tahun
    // if(pecahtanggal[3]=="Sunday,") {}
    // else {cekformattanggal+=1;}
    if(pecahtanggal[0].trim()=="" || pecahtanggal[1].trim()=="" || pecahtanggal[2].trim()=="" || pecahtanggal[3].trim()=="") {
      Swal.fire({
        title: 'Oops...',
        html: 'Jangan kosongkan Tanggal Target ya <i class="fa fa-smile-beam"></i>',
        icon: 'info',
        confirmButtonColor: basecolor1
      });
      return false;
    }
    else {
      if(cekformattanggal>0) {
        Swal.fire({
          title: 'Oops...',
          html: 'Silahkan masukkan format Tanggal dengan benar ya <i class="fa fa-smile-beam"></i>',
          icon: 'info',
          confirmButtonColor: basecolor1
        });
        return false;
      }
      else { 
        if(tanggaltarget.trim()!=="") {
          let pecahdate = tanggaltarget.split(" ");
          if(pecahdate[0]=="" || pecahdate[1]=="" || pecahdate[2]=="" || pecahdate[3]=="") {
            // Notif tanggal ga bener
            // var tanggaltarget = '';
            // settanggal('');
            // $("#tanggal").val('');
            return false;
          }
          else {
            var dayname0 = pecahdate[0];
            var dayname = "";
            var monthname = "";
            var monthname0 = pecahdate[2];
            // konversi hari
            switch (dayname0) {
              case "Sunday,": dayname = "Minggu,"; break;
              case "Minggu,": dayname = "Minggu,"; break;
              case "Monday,": dayname = "Senin,"; break;
              case "Senin,": dayname = "Senin,"; break;
              case "Tuesday,": dayname = "Selasa,"; break;
              case "Selasa,": dayname = "Selasa,"; break;
              case "Wednesday,": dayname = "Rabu,"; break;
              case "Rabu,": dayname = "Rabu,"; break;
              case "Thursday,": dayname = "Kamis,"; break;
              case "Kamis,": dayname = "Kamis,"; break;
              case "Friday,": dayname = "Jumat,"; break;
              case "Jumat,": dayname = "Jumat,"; break;
              case "Saturday,": dayname = "Sabtu,"; break;
              case "Sabtu,": dayname = "Sabtu,"; break;
              default: dayname = "";settanggal('');$("#tanggal").val('');break;
            }
            // konversi bulan
            switch (monthname0) {
              case "January": monthname = "Januari"; break;
              case "Januari": monthname = "Januari"; break;
              case "01": monthname = "Januari"; break;
              case "February": monthname = "Februari"; break;
              case "Februari": monthname = "Februari"; break;
              case "02": monthname = "Februari"; break;
              case "March": monthname = "Maret"; break;
              case "Maret": monthname = "Maret"; break;
              case "03": monthname = "Maret"; break;
              case "April": monthname = "April"; break;
              case "April": monthname = "April"; break;
              case "04": monthname = "April"; break;
              case "May": monthname = "Mei"; break;
              case "Mei": monthname = "Mei"; break;
              case "05": monthname = "Mei"; break;
              case "June": monthname = "Juni"; break;
              case "Juni": monthname = "Juni"; break;
              case "06": monthname = "Juni"; break;
              case "July": monthname = "Juli"; break;
              case "Juli": monthname = "Juli"; break;
              case "07": monthname = "Juli"; break;
              case "August": monthname = "Agustus"; break;
              case "Agustus": monthname = "Agustus"; break;
              case "08": monthname = "Agustus"; break;
              case "September": monthname = "September"; break;
              case "September": monthname = "September"; break;
              case "09": monthname = "September"; break;
              case "October": monthname = "Oktober"; break;
              case "Oktober": monthname = "Oktober"; break;
              case "10": monthname = "Oktober"; break;
              case "November": monthname = "November"; break;
              case "November": monthname = "November"; break;
              case "11": monthname = "November"; break;
              case "December": monthname = "Desember"; break;
              case "Desember": monthname = "Desember"; break;
              case "12": monthname = "Desember"; break;
              default: monthname = "";settanggal('');$("#tanggal").val('');break;
            }
            var newdate = dayname + ' ' + pecahdate[1] + ' ' + monthname + ' ' + pecahdate[3];
            // if(tanggaltarget.trim()=="") {
              // $(".tanggaltarget").val(newdate);
              // settanggal(newdate);
              var tanggaltarget = newdate;
            // }
          }
        }
        // var newtanggalfortanggal2 = pecahtanggal[3]+"-"+newbulan22+"-"+newtanggal22;
        // var newperiode = newbulan22+"/"+pecahtanggal[3];  
        $("form#formtambahtargetbelanja #submit").attr('disabled','disabled'); 
        $(".loading_riplabs").css('display','block');
        try { 
          let response = await axios.post(restapilink+"api/dompetku/tambahtargetbelanja", {
            key: appkey,
            token: token,
            namatarget: namatarget,
            jumlahbarangtarget: jumlahbarangtarget,
            totalhargatarget: totalhargatarget,
            tanggaltarget: tanggaltarget
          });
          if(response.data.status=="error") {
            $("form#formtambahtargetbelanja #submit").removeAttr('disabled');
            $(".loading_riplabs").css('display','none');
            Swal.fire({
              title: 'Oops...',
              html: response.data.message + ' <i class="fa fa-smile-beam"></i>',
              icon: 'error',
              confirmButtonColor: basecolor1
            });
            return false;
          }
          else {
            $("form#formtambahtargetbelanja #submit").removeAttr('disabled');
            $(".loading_riplabs").css('display','none');
            $(".modal .close").click();
            $("#namatarget").val('');
            $("#jumlahbarangtarget").val('');
            $("#totalhargatarget").val('');
            $("#tanggaltarget").val('');
            Swal.fire({
              title: 'Berhasil',
              html: 'Target Belanja baru sudah ditambahkan <i className="fa fa-smile-beam"></i>',
              icon: 'success',
              confirmButtonColor: basecolor1
            });
            setTimeout(function(){
              cekListTargetBelanja();
            }, 500);
          }
        }
        catch(e) {
          // console.log(e);
        }
      }
    }
    // End Validasi Tanggal
  }
  // Perbarui
  const perbaruiTargetBelanja = async (e) => { 
    e.preventDefault();  
    var id_targetedit = $("#popupedittargetbelanja #id_targetedit").val();
    var namatargetedit = $("#popupedittargetbelanja #namatargetedit").val();
    var jumlahbarangtargetedit = $("#popupedittargetbelanja #jumlahbarangtargetedit").val();
    var totalhargatargetedit = $("#popupedittargetbelanja #totalhargatargetedit").val();
    var tanggaltargetedit = $("#popupedittargetbelanja #tanggaltargetedit").val();

    var cekformattanggal = 0;
    var newtanggal22 = "";
    var newbulan22 = "";
    // var newtahun22 = "";
    // Validasi Tanggal
    var pecahtanggal = tanggaltargetedit.split(" ");
    // Konversi hari
    if(pecahtanggal[0]=="Sunday,") {}
    else if(pecahtanggal[0]=="Minggu,") {}
    else if(pecahtanggal[0]=="Monday,") {}
    else if(pecahtanggal[0]=="Senin,") {}
    else if(pecahtanggal[0]=="Tuesday,") {}
    else if(pecahtanggal[0]=="Selasa,") {}
    else if(pecahtanggal[0]=="Wednesday,") {}
    else if(pecahtanggal[0]=="Rabu,") {}
    else if(pecahtanggal[0]=="Thursday,") {}
    else if(pecahtanggal[0]=="Kamis,") {}
    else if(pecahtanggal[0]=="Friday,") {}
    else if(pecahtanggal[0]=="Jumat,") {}
    else if(pecahtanggal[0]=="Jumat,") {}
    else if(pecahtanggal[0]=="Saturday,") {}
    else if(pecahtanggal[0]=="Sabtu,") {}
    else {cekformattanggal+=1;} 
    // Konversi hari 
    if(pecahtanggal[1]=="01") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="02") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="03") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="04") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="05") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="06") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="07") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="08") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="09") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="2") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="3") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="4") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="5") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="6") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="7") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="8") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="9") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="10") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="11") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="12") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="13") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="14") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="15") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="16") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="17") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="18") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="19") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="20") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="21") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="22") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="23") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="24") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="25") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="26") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="27") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="28") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="29") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="30") {newtanggal22+=pecahtanggal[1];}
    else if(pecahtanggal[1]=="31") {newtanggal22+=pecahtanggal[1];}
    else {cekformattanggal+=1;}
    // Konversi bulan
    if(pecahtanggal[2]=="January") {newbulan22+="01";}
    else if(pecahtanggal[2]=="Januari") {newbulan22+="01";}
    else if(pecahtanggal[2]=="February") {newbulan22+="02";}
    else if(pecahtanggal[2]=="Februari") {newbulan22+="02";}
    else if(pecahtanggal[2]=="March") {newbulan22+="03";}
    else if(pecahtanggal[2]=="Maret") {newbulan22+="03";}
    else if(pecahtanggal[2]=="April") {newbulan22+="04";}
    else if(pecahtanggal[2]=="April") {newbulan22+="04";}
    else if(pecahtanggal[2]=="May") {newbulan22+="05";}
    else if(pecahtanggal[2]=="Mei") {newbulan22+="05";}
    else if(pecahtanggal[2]=="June") {newbulan22+="06";}
    else if(pecahtanggal[2]=="Juni") {newbulan22+="06";}
    else if(pecahtanggal[2]=="July") {newbulan22+="07";}
    else if(pecahtanggal[2]=="Juli") {newbulan22+="07";}
    else if(pecahtanggal[2]=="August") {newbulan22+="08";}
    else if(pecahtanggal[2]=="Agustus") {newbulan22+="08";}
    else if(pecahtanggal[2]=="September") {newbulan22+="09";}
    else if(pecahtanggal[2]=="September") {newbulan22+="09";}
    else if(pecahtanggal[2]=="October") {newbulan22+="10";}
    else if(pecahtanggal[2]=="Oktober") {newbulan22+="10";}
    else if(pecahtanggal[2]=="November") {newbulan22+="11";}
    else if(pecahtanggal[2]=="November") {newbulan22+="11";}
    else if(pecahtanggal[2]=="December") {newbulan22+="12";}
    else if(pecahtanggal[2]=="Desember") {newbulan22+="12";}
    else {cekformattanggal+=1;}
    // Konversi tahun
    // if(pecahtanggal[3]=="Sunday,") {}
    // else {cekformattanggal+=1;}
    if(pecahtanggal[0].trim()=="" || pecahtanggal[1].trim()=="" || pecahtanggal[2].trim()=="" || pecahtanggal[3].trim()=="") {
      Swal.fire({
        title: 'Oops...',
        html: 'Jangan kosongkan Tanggal Target ya <i class="fa fa-smile-beam"></i>',
        icon: 'info',
        confirmButtonColor: basecolor1
      });
      return false;
    }
    else {
      if(cekformattanggal>0) {
        Swal.fire({
          title: 'Oops...',
          html: 'Silahkan masukkan format Tanggal dengan benar ya <i class="fa fa-smile-beam"></i>',
          icon: 'info',
          confirmButtonColor: basecolor1
        });
        return false;
      }
      else { 
        if(tanggaltargetedit.trim()!=="") {
          let pecahdate = tanggaltargetedit.split(" ");
          if(pecahdate[0]=="" || pecahdate[1]=="" || pecahdate[2]=="" || pecahdate[3]=="") {
            // Notif tanggal ga bener
            // var tanggaltargetedit = '';
            // settanggal('');
            // $("#tanggal").val('');
            return false;
          }
          else {
            var dayname0 = pecahdate[0];
            var dayname = "";
            var monthname = "";
            var monthname0 = pecahdate[2];
            // konversi hari
            switch (dayname0) {
              case "Sunday,": dayname = "Minggu,"; break;
              case "Minggu,": dayname = "Minggu,"; break;
              case "Monday,": dayname = "Senin,"; break;
              case "Senin,": dayname = "Senin,"; break;
              case "Tuesday,": dayname = "Selasa,"; break;
              case "Selasa,": dayname = "Selasa,"; break;
              case "Wednesday,": dayname = "Rabu,"; break;
              case "Rabu,": dayname = "Rabu,"; break;
              case "Thursday,": dayname = "Kamis,"; break;
              case "Kamis,": dayname = "Kamis,"; break;
              case "Friday,": dayname = "Jumat,"; break;
              case "Jumat,": dayname = "Jumat,"; break;
              case "Saturday,": dayname = "Sabtu,"; break;
              case "Sabtu,": dayname = "Sabtu,"; break;
              default: dayname = "";settanggal('');$("#tanggal").val('');break;
            }
            // konversi bulan
            switch (monthname0) {
              case "January": monthname = "Januari"; break;
              case "Januari": monthname = "Januari"; break;
              case "01": monthname = "Januari"; break;
              case "February": monthname = "Februari"; break;
              case "Februari": monthname = "Februari"; break;
              case "02": monthname = "Februari"; break;
              case "March": monthname = "Maret"; break;
              case "Maret": monthname = "Maret"; break;
              case "03": monthname = "Maret"; break;
              case "April": monthname = "April"; break;
              case "April": monthname = "April"; break;
              case "04": monthname = "April"; break;
              case "May": monthname = "Mei"; break;
              case "Mei": monthname = "Mei"; break;
              case "05": monthname = "Mei"; break;
              case "June": monthname = "Juni"; break;
              case "Juni": monthname = "Juni"; break;
              case "06": monthname = "Juni"; break;
              case "July": monthname = "Juli"; break;
              case "Juli": monthname = "Juli"; break;
              case "07": monthname = "Juli"; break;
              case "August": monthname = "Agustus"; break;
              case "Agustus": monthname = "Agustus"; break;
              case "08": monthname = "Agustus"; break;
              case "September": monthname = "September"; break;
              case "September": monthname = "September"; break;
              case "09": monthname = "September"; break;
              case "October": monthname = "Oktober"; break;
              case "Oktober": monthname = "Oktober"; break;
              case "10": monthname = "Oktober"; break;
              case "November": monthname = "November"; break;
              case "November": monthname = "November"; break;
              case "11": monthname = "November"; break;
              case "December": monthname = "Desember"; break;
              case "Desember": monthname = "Desember"; break;
              case "12": monthname = "Desember"; break;
              default: monthname = "";settanggal('');$("#tanggal").val('');break;
            }
            var newdate = dayname + ' ' + pecahdate[1] + ' ' + monthname + ' ' + pecahdate[3];
            // if(tanggal.trim()=="") {
              // $(".tanggal").val(newdate);
              // settanggal(newdate);
              var tanggaltargetedit = newdate;
            // }
          }
        }
        // var newtanggalfortanggal2 = pecahtanggal[3]+"-"+newbulan22+"-"+newtanggal22;
        // var newperiode = newbulan22+"/"+pecahtanggal[3]; 
        $("form#formedittargetbelanja #submit").attr('disabled','disabled'); 
        $(".loading_riplabs").css('display','block');
        try {
          let response = await axios.post(restapilink+"api/dompetku/perbaruitargetbelanja", {
            key: appkey,
            token: token,
            id_targetedit: id_targetedit,
            namatargetedit: namatargetedit,
            jumlahbarangtargetedit: jumlahbarangtargetedit,
            totalhargatargetedit: totalhargatargetedit,
            tanggaltargetedit: tanggaltargetedit
          });
          if(response.data.status=="error") {
            $("form#formedittargetbelanja #submit").removeAttr('disabled');
            $(".loading_riplabs").css('display','none');
            Swal.fire({
              title: 'Oops...',
              html: response.data.message + ' <i class="fa fa-smile-beam"></i>',
              icon: 'error',
              confirmButtonColor: basecolor1
            });
            return false;
          }
          else {
            $("form#formedittargetbelanja #submit").removeAttr('disabled');
            $(".loading_riplabs").css('display','none');
            $(".modal .close").click(); 
            Swal.fire({
              title: 'Berhasil',
              html: 'Target Belanja sudah diperbarui <i className="fa fa-smile-beam"></i>',
              icon: 'success',
              confirmButtonColor: basecolor1
            });
            $(".boxlisttargetbelanja"+id_targetedit+" .target_nama").html(namatargetedit+" ("+jumlahbarangtargetedit+"x)"); 
            $(".boxlisttargetbelanja"+id_targetedit+" .target_harga").html(totalhargatargetedit);
            $(".boxlisttargetbelanja"+id_targetedit+" .target_tanggal").html("Target: "+tanggaltargetedit);
          } 
        }
        catch(e) {
          // console.log(e);
        }
      }
    }
    // End Validasi Tanggal
  }
  // Hapus
  const hapusTargetBelanja = async (e) => {
    Swal.fire({
      title: 'Konfirmasi',
      html: 'Apakah kamu yakin ingin menghapus Target ini?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: basecolor1,
      cancelButtonColor: basecolorred,
      confirmButtonText: 'Ya, Lanjutkan',
      cancelButtonText: 'Tidak'
    }).then((result) => {
      if (result.value) {
        $(".loading_riplabs").css('display','block');
        try {
          axios.post(restapilink+"api/dompetku/hapustargetbelanja", {
            key: appkey,
            token: token,
            id_target: e
          });
          $(".loading_riplabs").css('display','none');
          $(".modal .close").click();
          Swal.fire({
            title: 'Berhasil',
            html: 'Target Belanja sudah dihapus <i className="fa fa-smile-beam"></i>',
            icon: 'success',
            confirmButtonColor: basecolor1
          });
          setTimeout(function(){
            cekListTargetBelanja();
          }, 500);
        }
        catch(e) {
          // console.log(e);
        }
      }
    });
  }
  // Switch Status 
  const switchStatusTargetBelanja = async (e) => {
    var pecahe = e.split("_");
    var id = pecahe[0];
    var status = pecahe[1];
    if(status=="process") {
      var statusteks1 = "menyelesaikan";
      var statusteks2 = "diselesaikan";
    }
    else {
      var statusteks1 = "mengembalikan";
      var statusteks2 = "dikembalikan";
    }
    Swal.fire({
      title: 'Konfirmasi',
      html: 'Apakah kamu yakin ingin '+statusteks1+' Target ini?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: basecolor1,
      cancelButtonColor: basecolorred,
      confirmButtonText: 'Ya, Lanjutkan',
      cancelButtonText: 'Tidak'
    }).then((result) => {
      if (result.value) {
        $(".loading_riplabs").css('display','block');
        try {
          axios.post(restapilink+"api/dompetku/switchtargetbelanja", {
            key: appkey,
            token: token,
            id_target: id
          });
          $(".loading_riplabs").css('display','none');
          $(".modal .close").click();
          Swal.fire({
            title: 'Berhasil',
            html: 'Target Belanja sudah '+statusteks2+' <i className="fa fa-smile-beam"></i>',
            icon: 'success',
            confirmButtonColor: basecolor1
          });
          setTimeout(function(){
            cekListTargetBelanja();
          }, 500);
        }
        catch(e) {
          // console.log(e);
        }
      }
    });
  }
  // End Page Target Belanja 

  // Page Lainnya 
  // End Page Lainnya 

  // const refresh = (e) => {
  //   e.preventDefault();
  //   ReloadDompetkuHomepage();
  // }

  const menudompetkuberanda = async(e) => {
    cekTransaksiTerakhir(); 
    cekDompetSaya();
    cekLaporanKeuangan(); 
    // console.log('page homepage');
  }
  const menudompetkutransaksi = async(e) => {
    cekListPeriodeTransaksi(); 
    cekDompetSaya();
    cekListTransaksi();
    cekLaporanKeuangan(); 
    // console.log('page transaksi');
  }
  const menudompetkulainnya = async(e) => {
    cekDompetSaya();
    // console.log('page lainnya');
  }
  const menudompetkutargetbelanja = async(e) => {
    cekDompetSaya();
    cekListTargetBelanja();
    // console.log('page targetbelanja');
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(listtargetbelanja.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(listtargetbelanja.length / itemsPerPage));
  }

  const directlogin = async (e) => {
    if(appsetup=="utama") {
      localStorage.setItem('directlogin',baseurl+'dompetku/');
    }
    else {
      localStorage.setItem('directlogin',baseurl);
    }
    navigate("/login/");
  }

  const fetchDataGrafik1 = async () => {
    try {
      let response = await axios.post(restapilink + "api/dompetku/datagrafikdompetku1", {
        key: appkey,
        token: token
      });
      if (response && response.data) {
        if (response.data.status === 'success') {
          const data = response.data.data;
          // Total saldo
          const totalSaldo = data.reduce((acc, item) => acc + parseFloat(item.dompetku_saldo), 0);
          // Format data for Highcharts
          const totalPorto = data.reduce((acc, item) => acc + parseInt(item.dompetku_saldo, 10), 0);
          const seriesData = data.map(item => ({
            name: item.dompetku_nama + " ("+(parseFloat(item.dompetku_saldo) / totalSaldo * 100).toFixed(2)+"%)",
            y: parseFloat(item.dompetku_saldo)
          }));
          const formatRupiah = (number) => {
            return new Intl.NumberFormat('id-ID', {
              style: 'currency',
              currency: 'IDR',
              minimumFractionDigits: 0
            }).format(number);
          };
          setChartOptions({
            title: { text: 'Portofolio DompetKu (' + formatRupiah(totalPorto) + ')' },
            chart: { type: 'pie' },
            legend: { enabled: true },
            tooltip: { pointFormat: '{series.name}: <b>{point.y}</b>'},
            plotOptions: {
              pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                  enabled: false
                },
                showInLegend: true
              },
              series: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: [{
                  enabled: true,
                  distance: 20
                }, 
                {
                  enabled: true,
                  distance: -40,
                  formatter: function() {
                    return `${formatRupiah(this.y)}<br/>${this.percentage.toFixed(1)}%`;
                  },
                  style: {
                    fontSize: '0.9em',
                    textOutline: 'none',
                    opacity: 0.7
                  },
                  filter: {
                    operator: '>',
                    property: 'percentage',
                    value: 10
                  }
                }]
              }
            },
            series: [{
              name: 'Saldo',
              colorByPoint: true,
              data: seriesData
            }]
          });
        } 
        else {
          console.error('Error fetching data:', response.data.message);
        }
      } 
      else {
        console.error('Error fetching data: No response data');
      }
    } 
    catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleSelectChange = async (e) => {
    const selectedValue = e.target.value;
    const selectedOption = e.target.options[e.target.selectedIndex];
    const selectedValuedompetku_nama = selectedOption.getAttribute('dompetku_nama');
    Swal.fire({
      title: 'Konfirmasi',
      html: 'Kamu ingin membuka Dompet '+selectedValuedompetku_nama+'?',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: basecolor1,
      cancelButtonColor: 'red',
      confirmButtonText: 'Ya, Lanjutkan',
      cancelButtonText: 'Tidak'
    }).then( async (result) => {
      if (result.value) {
        try {
          let response = await axios.post(restapilink + "api/dompetku/gantidompetdipilih", {
            key: appkey,
            token: token,
            id_dompetku: selectedValue
          });
          if (response.data.status === 'success') {
            Swal.fire({
              title: 'Berhasil',
              html: 'Dompet '+selectedValuedompetku_nama+' sudah dipilih',
              icon: 'success',
              confirmButtonColor: basecolor1
            });
            setTimeout(() => {
              window.location.reload();
            }, 1500);
          } 
          else {
            alert('Gagal memperbarui data: ' + response.data.message);
            Swal.fire({
              title: 'Oops...',
              html: 'Gagal memilih dompet '+selectedValuedompetku_nama+'',
              icon: 'error',
              confirmButtonColor: basecolor1
            });
          }
        } 
        catch (error) {
          alert('Terjadi kesalahan: ' + error.message);
          Swal.fire({
            title: 'Oops...',
            html: 'Gagal memilih dompet '+selectedValuedompetku_nama+'',
            icon: 'error',
            confirmButtonColor: basecolor1
          });
        }
      }
    });
  };

  useEffect(() => {
    getCekLogin2();
    // cekListTransaksiTes();
    // if(ceksesilogin2 > 0 && PenggunaPopupIntro3 == 'no') {
    //   setTimeout(function() {
    //     $(".showmodalintroutama").click();
    //   }, 2000);
    // }
    if(ceksesilogin2>0) {
      // Page Home
      if(otherpage=="" || otherpage!=="transaksi" && otherpage!=="lainnya" && otherpage!=="targetbelanja" && otherpage!=="rangkumantransaksi") {
        cekTransaksiTerakhir(); 
        cekDompetSaya();
        cekLaporanKeuangan(); 
        // console.log('page homepage');
      }
      // Page Transaksi
      else if(otherpage=="transaksi") {
        cekListPeriodeTransaksi(); 
        cekDompetSaya();
        cekListTransaksi();
        cekLaporanKeuangan(); 
        // console.log('page transaksi');
      }
      // Page Lainnya
      else if(otherpage=="lainnya") {
        cekDompetSaya();
        // console.log('page lainnya');
      }
      // Page TargetBelanja 
      else if(otherpage=="targetbelanja") {
        cekDompetSaya();
        cekListTargetBelanja();
        // console.log('page targetbelanja');
        const endOffset = itemOffset + itemsPerPage;
        setCurrentItems(listtargetbelanja.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(listtargetbelanja.length / itemsPerPage));
      }
    }

    setTimeout(function(){
      setloadpagedompetku(1);
      fetchDataGrafik1();
    }, 1000);

  }, [periodetransaksiactive, lappemasukanbulanini, lappengeluaranbulanini, searchTransaksi, jumlahlisttargetbelanja, searchTargetBelanja, statusTargetBelanja, loaddatatargetbelanja, itemOffset, itemsPerPage, iddompetkudompetdipilihsaya, saldoSaya, jumlahDompetSaya, jumlahdatatransaksiterakhir, dompet, ceksesilogin2, PenggunaPopupIntro3 ]);

  $(".showpopupedittransaksi").on('click',function(){
    var id = $(this).attr("id"); 
    showPopupEditTransaksi(id); 
  });

  // setTimeout(function() {
  //   document.location.href=baseurl+'dashboard/';
  // }, 1800000); 
  
  // Page Lainnya
  $(".kalkukasikansaldo").on('keyup click',function(){
    var saldopositif = 0;
    var saldonegatif = 0;  
    var penambahan1X = $("#penambahan1").val();
    if(penambahan1X.trim()=="") {var penambahan1=0;} else {var penambahan1 = penambahan1X.replaceAll(",","");}
    var penambahan2X = $("#penambahan2").val();
    if(penambahan2X.trim()=="") {var penambahan2=0;} else {var penambahan2 = penambahan2X.replaceAll(",","");}
    var penambahan3X = $("#penambahan3").val();
    if(penambahan3X.trim()=="") {var penambahan3=0;} else {var penambahan3 = penambahan3X.replaceAll(",","");}
    var penambahan4X = $("#penambahan4").val();
    if(penambahan4X.trim()=="") {var penambahan4=0;} else {var penambahan4 = penambahan4X.replaceAll(",","");}
    var penambahan5X = $("#penambahan5").val();
    if(penambahan5X.trim()=="") {var penambahan5=0;} else {var penambahan5 = penambahan5X.replaceAll(",","");}
    var pengurangan1X = $("#pengurangan1").val();
    if(pengurangan1X.trim()=="") {var pengurangan1=0;} else {var pengurangan1 = pengurangan1X.replaceAll(",","");}
    var pengurangan2X = $("#pengurangan2").val();
    if(pengurangan2X.trim()=="") {var pengurangan2=0;} else {var pengurangan2 = pengurangan2X.replaceAll(",","");}
    saldopositif += parseInt(penambahan1);
    saldopositif += parseInt(penambahan2);
    saldopositif += parseInt(penambahan3);
    saldopositif += parseInt(penambahan4);
    saldopositif += parseInt(penambahan5);
    saldonegatif += parseInt(pengurangan1);
    saldonegatif += parseInt(pengurangan2);
    var total = saldopositif - saldonegatif; 
    if(total<0) { $(".totalkalkulasikansaldo").html("Rp -"+rupiah2(Math.abs(total))); }
    else if(total==0) { $(".totalkalkulasikansaldo").html("Rp "+rupiah2(Math.abs(total))); }
    else if(total>0) { $(".totalkalkulasikansaldo").html("Rp "+rupiah2(Math.abs(total))); }
  });
  // End Page Lainnya
  // Page Target Belanja 
  
  // End Page Target Belanja 

  // setTimeout(function(){
  //   if(ceksesilogin2 <= 0) {
  //     localStorage.removeItem('token'); 
  //     navigate('/login'); 
  //   }
  // }, 1000);

  var hitlaptotalpemasukanpengeluaranbulanini = lappemasukanbulanini - lappengeluaranbulanini;
  var laptotalpemasukanpengeluaranbulanini = "";
  if(hitlaptotalpemasukanpengeluaranbulanini<0) {
    laptotalpemasukanpengeluaranbulanini += "-Rp "+rupiah2(Math.abs(hitlaptotalpemasukanpengeluaranbulanini));
    var classlaptotal = "font-medium colorred";
  }
  else if(hitlaptotalpemasukanpengeluaranbulanini==0) {
    laptotalpemasukanpengeluaranbulanini += "Rp 0";
    var classlaptotal = "font-medium";
  }
  else if(hitlaptotalpemasukanpengeluaranbulanini>0) {
    laptotalpemasukanpengeluaranbulanini += "+Rp "+rupiah2(hitlaptotalpemasukanpengeluaranbulanini);
    var classlaptotal = "font-medium colorgreen";
  }

  // Other Scripts
  // Header Dompetku
  $(".tambahtransaksi").click(function(){
    setTimeout(function(){
      $("#jumlah").focus();
    }, 800);
  });
  
  $('#jumlah').mask('000,000,000,000,000,000,000,000', { reverse: true });
  $('#jumlahedit').mask('000,000,000,000,000,000,000,000', { reverse: true });
  $('#penambahan1').mask('000,000,000,000,000,000,000,000', { reverse: true });
  $('#penambahan2').mask('000,000,000,000,000,000,000,000', { reverse: true });
  $('#penambahan3').mask('000,000,000,000,000,000,000,000', { reverse: true });
  $('#penambahan4').mask('000,000,000,000,000,000,000,000', { reverse: true });
  $('#penambahan5').mask('000,000,000,000,000,000,000,000', { reverse: true });
  $('#pengurangan1').mask('000,000,000,000,000,000,000,000', { reverse: true });
  $('#pengurangan2').mask('000,000,000,000,000,000,000,000', { reverse: true });
  $('#totalhargatarget').mask('000,000,000,000,000,000,000,000', { reverse: true });
  $('#totalhargatargetedit').mask('000,000,000,000,000,000,000,000', { reverse: true });

  $('.tanggal').datepicker({
    'format': 'DD, dd MM yyyy',
    'autoclose': true,
    'endDate': 'today'
  });
  $('.tanggaledit').datepicker({
    'format': 'DD, dd MM yyyy',
    'autoclose': true,
    'endDate': 'today'
  });
  $('.tanggaltarget').datepicker({
    'format': 'DD, dd MM yyyy',
    'autoclose': true
  });
  $('.tanggaltargetedit').datepicker({
    'format': 'DD, dd MM yyyy',
    'autoclose': true
  });
  var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  var d = new Date();
  var dayName = days[d.getDay()];
  var datenow = String(d.getDate()).padStart(2, '0'); 
  const lastyear = d.getFullYear() - 1;  
  const yearnow = d.getFullYear();         
  var lastmonth0 = d.getMonth(); // 👈️ months are 0-based
  var last2month0 = d.getMonth() - 1; // 👈️ months are 0-based
  var last3month0 = d.getMonth() - 3; // 👈️ months are 0-based
  const monthnow = d.getMonth() + 1; // 👈️ months are 0-based
  const daysInCurrentMonth = getDaysInMonth(yearnow, monthnow); 
  if(monthnow < 10) { var monthnow2 = "0"+monthnow;}  
  else {var monthnow2 = monthnow;}  
  setTimeout(function() { 
    var e = dayName+", "+datenow+" "+monthnow2+" "+yearnow;
    if(e.trim()!=="") {
      let pecahdate = e.split(" ");
      if(pecahdate[0]=="" || pecahdate[1]=="" || pecahdate[2]=="" || pecahdate[3]=="") {
        settanggal('');
        $("#tanggal").val('');
        $(".tanggaltarget").val('');
      }
      else {
        var dayname0 = pecahdate[0];
        var dayname = "";
        var monthname = "";
        var monthname0 = pecahdate[2];
        // konversi hari
        switch (dayname0) {
          case "Sunday,": dayname = "Minggu,"; break;
          case "Minggu,": dayname = "Minggu,"; break;
          case "Monday,": dayname = "Senin,"; break;
          case "Senin,": dayname = "Senin,"; break;
          case "Tuesday,": dayname = "Selasa,"; break;
          case "Selasa,": dayname = "Selasa,"; break;
          case "Wednesday,": dayname = "Rabu,"; break;
          case "Rabu,": dayname = "Rabu,"; break;
          case "Thursday,": dayname = "Kamis,"; break;
          case "Kamis,": dayname = "Kamis,"; break;
          case "Friday,": dayname = "Jumat,"; break;
          case "Jumat,": dayname = "Jumat,"; break;
          case "Saturday,": dayname = "Sabtu,"; break;
          case "Sabtu,": dayname = "Sabtu,"; break;
          default: dayname = "";settanggal('');$("#tanggal").val('');$(".tanggaltarget").val('');break;
        }
        // konversi bulan
        switch (monthname0) {
          case "January": monthname = "Januari"; break;
          case "Januari": monthname = "Januari"; break;
          case "01": monthname = "Januari"; break;
          case "February": monthname = "Februari"; break;
          case "Februari": monthname = "Februari"; break;
          case "02": monthname = "Februari"; break;
          case "March": monthname = "Maret"; break;
          case "Maret": monthname = "Maret"; break;
          case "03": monthname = "Maret"; break;
          case "April": monthname = "April"; break;
          case "April": monthname = "April"; break;
          case "04": monthname = "April"; break;
          case "May": monthname = "Mei"; break;
          case "Mei": monthname = "Mei"; break;
          case "05": monthname = "Mei"; break;
          case "June": monthname = "Juni"; break;
          case "Juni": monthname = "Juni"; break;
          case "06": monthname = "Juni"; break;
          case "July": monthname = "Juli"; break;
          case "Juli": monthname = "Juli"; break;
          case "07": monthname = "Juli"; break;
          case "August": monthname = "Agustus"; break;
          case "Agustus": monthname = "Agustus"; break;
          case "08": monthname = "Agustus"; break;
          case "September": monthname = "September"; break;
          case "September": monthname = "September"; break;
          case "09": monthname = "September"; break;
          case "October": monthname = "Oktober"; break;
          case "Oktober": monthname = "Oktober"; break;
          case "10": monthname = "Oktober"; break;
          case "November": monthname = "November"; break;
          case "November": monthname = "November"; break;
          case "11": monthname = "November"; break;
          case "December": monthname = "Desember"; break;
          case "Desember": monthname = "Desember"; break;
          case "12": monthname = "Desember"; break;
          default: monthname = "";settanggal('');$("#tanggal").val('');$(".tanggaltarget").val('');break;
        }
        var newdate = dayname + ' ' + pecahdate[1] + ' ' + monthname + ' ' + pecahdate[3];
        if(tanggal.trim()=="") {
          $(".tanggal").val(newdate);
          $(".tanggaltarget").val(newdate);
          settanggal(newdate);
        }
      }
    }
  }, 500);
  $("#tambahtransaksi *").click(function(){
    setTimeout(function() {
      var e = $(".tanggal").val();
      if(e.trim()!=="") {
        let pecahdate = e.split(" ");
        if(pecahdate[0]=="" || pecahdate[1]=="" || pecahdate[2]=="" || pecahdate[3]=="") {
          settanggal('');
        }
        else {
          var dayname0 = pecahdate[0];
          var dayname = "";
          var monthname = "";
          var monthname0 = pecahdate[2];
          // konversi hari
          switch (dayname0) {
            case "Sunday,": dayname = "Minggu,"; break;
            case "Minggu,": dayname = "Minggu,"; break;
            case "Monday,": dayname = "Senin,"; break;
            case "Senin,": dayname = "Senin,"; break;
            case "Tuesday,": dayname = "Selasa,"; break;
            case "Selasa,": dayname = "Selasa,"; break;
            case "Wednesday,": dayname = "Rabu,"; break;
            case "Rabu,": dayname = "Rabu,"; break;
            case "Thursday,": dayname = "Kamis,"; break;
            case "Kamis,": dayname = "Kamis,"; break;
            case "Friday,": dayname = "Jumat,"; break;
            case "Jumat,": dayname = "Jumat,"; break;
            case "Saturday,": dayname = "Sabtu,"; break;
            case "Sabtu,": dayname = "Sabtu,"; break;
            default: dayname = "";settanggal(''); break;
          }
          // konversi bulan
          switch (monthname0) {
            case "January": monthname = "Januari"; break;
            case "Januari": monthname = "Januari"; break;
            case "February": monthname = "Februari"; break;
            case "Februari": monthname = "Februari"; break;
            case "March": monthname = "Maret"; break;
            case "Maret": monthname = "Maret"; break;
            case "April": monthname = "April"; break;
            case "April": monthname = "April"; break;
            case "May": monthname = "Mei"; break;
            case "Mei": monthname = "Mei"; break;
            case "June": monthname = "Juni"; break;
            case "Juni": monthname = "Juni"; break;
            case "July": monthname = "Juli"; break;
            case "Juli": monthname = "Juli"; break;
            case "August": monthname = "Agustus"; break;
            case "Agustus": monthname = "Agustus"; break;
            case "September": monthname = "September"; break;
            case "September": monthname = "September"; break;
            case "October": monthname = "Oktober"; break;
            case "Oktober": monthname = "Oktober"; break;
            case "November": monthname = "November"; break;
            case "November": monthname = "November"; break;
            case "December": monthname = "Desember"; break;
            case "Desember": monthname = "Desember"; break;
            default: monthname = "";settanggal(''); break;
          }
          var newdate = dayname + ' ' + pecahdate[1] + ' ' + monthname + ' ' + pecahdate[3];
          $(".tanggal").val(newdate);
          settanggal(newdate);
        }
      }
    }, 500);
  })
  $("#popupedittransaksi *").click(function(){
    setTimeout(function() {
      var e = $(".tanggaledit").val();
      if(e.trim()!=="") {
        let pecahdate = e.split(" ");
        if(pecahdate[0]=="" || pecahdate[1]=="" || pecahdate[2]=="" || pecahdate[3]=="") {
          // settanggal('');
        }
        else {
          var dayname0 = pecahdate[0];
          var dayname = "";
          var monthname = "";
          var monthname0 = pecahdate[2];
          // konversi hari
          switch (dayname0) {
            case "Sunday,": dayname = "Minggu,"; break;
            case "Minggu,": dayname = "Minggu,"; break;
            case "Monday,": dayname = "Senin,"; break;
            case "Senin,": dayname = "Senin,"; break;
            case "Tuesday,": dayname = "Selasa,"; break;
            case "Selasa,": dayname = "Selasa,"; break;
            case "Wednesday,": dayname = "Rabu,"; break;
            case "Rabu,": dayname = "Rabu,"; break;
            case "Thursday,": dayname = "Kamis,"; break;
            case "Kamis,": dayname = "Kamis,"; break;
            case "Friday,": dayname = "Jumat,"; break;
            case "Jumat,": dayname = "Jumat,"; break;
            case "Saturday,": dayname = "Sabtu,"; break;
            case "Sabtu,": dayname = "Sabtu,"; break;
            default: dayname = "";settanggal(''); break;
          }
          // konversi bulan
          switch (monthname0) {
            case "January": monthname = "Januari"; break;
            case "Januari": monthname = "Januari"; break;
            case "February": monthname = "Februari"; break;
            case "Februari": monthname = "Februari"; break;
            case "March": monthname = "Maret"; break;
            case "Maret": monthname = "Maret"; break;
            case "April": monthname = "April"; break;
            case "April": monthname = "April"; break;
            case "May": monthname = "Mei"; break;
            case "Mei": monthname = "Mei"; break;
            case "June": monthname = "Juni"; break;
            case "Juni": monthname = "Juni"; break;
            case "July": monthname = "Juli"; break;
            case "Juli": monthname = "Juli"; break;
            case "August": monthname = "Agustus"; break;
            case "Agustus": monthname = "Agustus"; break;
            case "September": monthname = "September"; break;
            case "September": monthname = "September"; break;
            case "October": monthname = "Oktober"; break;
            case "Oktober": monthname = "Oktober"; break;
            case "November": monthname = "November"; break;
            case "November": monthname = "November"; break;
            case "December": monthname = "Desember"; break;
            case "Desember": monthname = "Desember"; break;
            default: monthname = "";settanggal(''); break;
          } 
          var newdate = dayname + ' ' + pecahdate[1] + ' ' + monthname + ' ' + pecahdate[3];
          $(".tanggaledit").val(newdate);
          // settanggal(newdate);
        }
      }
    }, 500);
  });
  $("#popuptambahtargetbelanja *").click(function(){
    setTimeout(function() {
      var e = $(".tanggaltarget").val();
      if(e.trim()!=="") {
        let pecahdate = e.split(" ");
        if(pecahdate[0]=="" || pecahdate[1]=="" || pecahdate[2]=="" || pecahdate[3]=="") {
          $(".tanggaltarget").val('');
        }
        else {
          var dayname0 = pecahdate[0];
          var dayname = "";
          var monthname = "";
          var monthname0 = pecahdate[2];
          // konversi hari
          switch (dayname0) {
            case "Sunday,": dayname = "Minggu,"; break;
            case "Minggu,": dayname = "Minggu,"; break;
            case "Monday,": dayname = "Senin,"; break;
            case "Senin,": dayname = "Senin,"; break;
            case "Tuesday,": dayname = "Selasa,"; break;
            case "Selasa,": dayname = "Selasa,"; break;
            case "Wednesday,": dayname = "Rabu,"; break;
            case "Rabu,": dayname = "Rabu,"; break;
            case "Thursday,": dayname = "Kamis,"; break;
            case "Kamis,": dayname = "Kamis,"; break;
            case "Friday,": dayname = "Jumat,"; break;
            case "Jumat,": dayname = "Jumat,"; break;
            case "Saturday,": dayname = "Sabtu,"; break;
            case "Sabtu,": dayname = "Sabtu,"; break;
            default: dayname = "";$(".tanggaltarget").val(''); break;
          }
          // konversi bulan
          switch (monthname0) {
            case "January": monthname = "Januari"; break;
            case "Januari": monthname = "Januari"; break;
            case "February": monthname = "Februari"; break;
            case "Februari": monthname = "Februari"; break;
            case "March": monthname = "Maret"; break;
            case "Maret": monthname = "Maret"; break;
            case "April": monthname = "April"; break;
            case "April": monthname = "April"; break;
            case "May": monthname = "Mei"; break;
            case "Mei": monthname = "Mei"; break;
            case "June": monthname = "Juni"; break;
            case "Juni": monthname = "Juni"; break;
            case "July": monthname = "Juli"; break;
            case "Juli": monthname = "Juli"; break;
            case "August": monthname = "Agustus"; break;
            case "Agustus": monthname = "Agustus"; break;
            case "September": monthname = "September"; break;
            case "September": monthname = "September"; break;
            case "October": monthname = "Oktober"; break;
            case "Oktober": monthname = "Oktober"; break;
            case "November": monthname = "November"; break;
            case "November": monthname = "November"; break;
            case "December": monthname = "Desember"; break;
            case "Desember": monthname = "Desember"; break;
            default: monthname = "";$(".tanggaltarget").val(''); break;
          }
          var newdate = dayname + ' ' + pecahdate[1] + ' ' + monthname + ' ' + pecahdate[3];
          $(".tanggaltarget").val(newdate);
        }
      }
    }, 500);
  })
  $("#popupedittargetbelanja *").click(function(){
    setTimeout(function() {
      var e = $(".tanggaltargetedit").val();
      if(e.trim()!=="") {
        let pecahdate = e.split(" ");
        if(pecahdate[0]=="" || pecahdate[1]=="" || pecahdate[2]=="" || pecahdate[3]=="") {
          
        }
        else {
          var dayname0 = pecahdate[0];
          var dayname = "";
          var monthname = "";
          var monthname0 = pecahdate[2];
          // konversi hari
          switch (dayname0) {
            case "Sunday,": dayname = "Minggu,"; break;
            case "Minggu,": dayname = "Minggu,"; break;
            case "Monday,": dayname = "Senin,"; break;
            case "Senin,": dayname = "Senin,"; break;
            case "Tuesday,": dayname = "Selasa,"; break;
            case "Selasa,": dayname = "Selasa,"; break;
            case "Wednesday,": dayname = "Rabu,"; break;
            case "Rabu,": dayname = "Rabu,"; break;
            case "Thursday,": dayname = "Kamis,"; break;
            case "Kamis,": dayname = "Kamis,"; break;
            case "Friday,": dayname = "Jumat,"; break;
            case "Jumat,": dayname = "Jumat,"; break;
            case "Saturday,": dayname = "Sabtu,"; break;
            case "Sabtu,": dayname = "Sabtu,"; break;
            default: dayname = "";$(".tanggaltargetedit").val(''); break;
          }
          // konversi bulan
          switch (monthname0) {
            case "January": monthname = "Januari"; break;
            case "Januari": monthname = "Januari"; break;
            case "February": monthname = "Februari"; break;
            case "Februari": monthname = "Februari"; break;
            case "March": monthname = "Maret"; break;
            case "Maret": monthname = "Maret"; break;
            case "April": monthname = "April"; break;
            case "April": monthname = "April"; break;
            case "May": monthname = "Mei"; break;
            case "Mei": monthname = "Mei"; break;
            case "June": monthname = "Juni"; break;
            case "Juni": monthname = "Juni"; break;
            case "July": monthname = "Juli"; break;
            case "Juli": monthname = "Juli"; break;
            case "August": monthname = "Agustus"; break;
            case "Agustus": monthname = "Agustus"; break;
            case "September": monthname = "September"; break;
            case "September": monthname = "September"; break;
            case "October": monthname = "Oktober"; break;
            case "Oktober": monthname = "Oktober"; break;
            case "November": monthname = "November"; break;
            case "November": monthname = "November"; break;
            case "December": monthname = "Desember"; break;
            case "Desember": monthname = "Desember"; break;
            default: monthname = "";$(".tanggaltargetedit").val(''); break;
          } 
          var newdate = dayname + ' ' + pecahdate[1] + ' ' + monthname + ' ' + pecahdate[3];
          $(".tanggaltargetedit").val(newdate);
        }
      }
    }, 500);
  });

  $(".formkategorijuduledit").on('mouseover',function(){
    var val = $(this).attr('placeholder');
    $(this).val(val);
  })
  $("#tambahtransaksi #kategori").on('change',function(){
    var tipe = $(this).find('option:selected').attr("tipe");
    if(tipe=="income") { 
      $("#tambahtransaksi .form-check-rangkumantransaksi label").html("Masukkan ke Rangkuman Pemasukan Saya");
    }
    else {
      $("#tambahtransaksi .form-check-rangkumantransaksi label").html("Masukkan ke Rangkuman Pengeluaran Saya");
    }
  });
  $("#popupedittransaksi #kategoriedit").on('change',function(){
    var tipe = $(this).find('option:selected').attr("tipe");
    if(tipe=="income") { 
      $("#popupedittransaksi .form-check-rangkumantransaksi label").html("Masukkan ke Rangkuman Pemasukan Saya");
    }
    else {
      $("#popupedittransaksi .form-check-rangkumantransaksi label").html("Masukkan ke Rangkuman Pengeluaran Saya");
    }
  })
  // End Header Dompetku

  if(appsetup!=="dompetku") {
    var linktopagedompetku = "/dompetku/";
    var linktopagedompetkulainnya = "/dompetku/lainnya/";
    var linktopagetransaksi = "/dompetku/transaksi/";
    var linktopagetargetbelanja = "/dompetku/targetbelanja/";
    var linktopagerangkumantransaksi = "/dompetku/rangkumantransaksi/";
  }
  else {
    var linktopagedompetku = "/";
    var linktopagedompetkulainnya = "/lainnya/";
    var linktopagetransaksi = "/transaksi/";
    var linktopagetargetbelanja = "/targetbelanja/";
    var linktopagerangkumantransaksi = "/rangkumantransaksi/";
  }
  // $(".listperiode").scrollTop($('.listperiode')[0].scrollHeight);


  var imggifdompetku = baseurlassets+"images/DompetKu.gif";
  var logodompetku = baseurlassets+"images/riplabs/Logo DompetKu.png";
  var logosharefiles = baseurlassets+"images/riplabs/Logo ShareFiles.png";

  return (
    <>
      <title>Aplikasi DompetKu | RipLabs Indonesia</title>
      <meta name="title" content="Aplikasi DompetKu | RipLabs Indonesia" />
      <meta itemProp="name" content="Aplikasi DompetKu | RipLabs Indonesia" />
      <meta property="og:title" content="Aplikasi DompetKu | RipLabs Indonesia" />
      <meta name="twitter:title" content="Aplikasi DompetKu | RipLabs Indonesia" />

      {/* {datalisttransaksitest} */}


      {
        loadpagedompetku > 0 && (
          <>
            {
              ceksesilogin2 <= 0 && (
                <>
                  <Header />
                  <div className="bodypage pagehome pagehome2 animated fadeIn">
                    <div className='hero'>
                      <div className='container text-center'>
                        <img src={logodompetku} style={{width:"220px",marginBottom:"20px"}} />
                        <img className='imggif' src={imggifdompetku} />
                        <p><span className='font-semibold' style={{color:"var(--basecolor1)"}}>Dompet</span><span className='font-semibold' style={{color:"var(--basecolor2)"}}>Ku</span> merupakan sebuah Aplikasi Manajemen Keuangan yang sangat ringan ini bisa kamu gunakan secara gratis di RipLabs.</p>
                        <span onClick={directlogin} className='btn_hero_1 cursorpointer'>Coba Sekarang&nbsp;<i className='fa fa-angle-right'></i></span>
                      </div>
                    </div>
                  </div>
                </>
              )
            }
            {
              ceksesilogin2 > 0 && (
                <>
                  <div className='dompetkupage bodypage container container-dompetku'> 
                    {
                      jumlahDompetSaya > 0 && (
                        <>
                          <div className='boxtotalsaldo d-block m-auto text-center'>
                            <small>Saldo Saya</small>
                            <h1 className="m-0 my-2">Rp {saldoSaya}</h1>
                            <div className='row justify-content-center'>
                              <div className='col-lg-3 col-6'>
                                <select required className='text-center form-select' id='dompet' onChange={handleSelectChange}>
                                {
                                  Array.isArray(datadompetsaya) ?
                                  datadompetsaya.map((data, i) => {
                                      const isSelected = data.dompetku_dipilih === "yes";
                                      return (
                                        <option key={i} selected={isSelected ? true : undefined} value={data.id_dompetku} dompetku_nama={data.dompetku_nama}>{data.dompetku_nama} (Rp. {rupiah2(data.dompetku_saldo)})</option>
                                      )
                                    })
                                  : ""
                                }
                                </select>
                              </div>
                            </div>
                          </div> 
                        </>
                      )
                    }

                    {/* Page Dompetku Utama */}
                    {
                      otherpage=="" || otherpage!=="transaksi" && otherpage!=="lainnya" && otherpage!=="targetbelanja" && otherpage!=="rangkumantransaksi" && (
                        <>
                          {/* <DompetKuHomepage /> */}
                          {/* <form method='POST' onSubmit={refresh}>
                            <button type="submit">refresh1</button>
                          </form> */}
                          {
                            jumlahDompetSaya > 0 && (
                            <div className='boxdompetku1 boxlistdompetsaya'>
                                <div className="header">
                                <h6>Dompet Saya</h6>
                                <span data-bs-toggle="modal" data-bs-target="#popupeditdompetsaya">Lihat Semua</span>
                                </div>
                                <hr/>
                                <div className='color6 d-flex align-items-center justify-content-between'>
                                <span>
                                    <i className='fa fa-wallet'></i> {datadompetdipilihsaya} 
                                </span>
                                <span>
                                    Rp {datasaldodompetdipilihsaya}
                                </span>
                                </div>
                            </div>
                            )
                          }
                          <div className='boxdompetku1 boxlisttransaksiterakhir'>
                            <div className="header">
                              <h6>Transaksi Terakhir</h6>
                              <NavLink to={linktopagetransaksi}>Lihat Semua</NavLink>
                            </div>
                            <hr/>
                            {
                              jumlahdatatransaksiterakhir <= 0 && (
                                <><center>Belum ada Transaksi</center></>
                              )
                            }
                            {
                              jumlahdatatransaksiterakhir > 0 && (
                                <>
                                  {
                                    Array.isArray(datatransaksiterakhir) ?
                                      datatransaksiterakhir.map((data, i) => { 
                                        var transaksitanggal = data.transaksi_tanggal.replaceAll("\\'","'");
                                        if(i==2) {
                                          var classname = "edittransaksi edittransaksi"+data.id_transaksi+" color2 d-flex align-items-center justify-content-between";
                                        }
                                        else {
                                          var classname = "mb-4 edittransaksi edittransaksi"+data.id_transaksi+" color2 d-flex align-items-center justify-content-between";
                                        }
                                        return (
                                          <div key={i} className={classname} style={{cursor:"pointer"}} id={data.id_transaksi} data-bs-toggle="modal" data-bs-target="#popupedittransaksi" onClick={(e) => showPopupEditTransaksi(e.currentTarget.id)}>
                                            <div>
                                              <h6 className='color2 m-0 mb-1'>{data.transaksi_kategori_judul}</h6>
                                              <small className='color6'>{transaksitanggal}</small>
                                            </div>
                                            {
                                              data.transaksi_kategori_tipe=="income" && (
                                                <><span className="colorgreen">{rupiah2(data.transaksi_jumlah)}</span></>
                                              )
                                            }
                                            {
                                              data.transaksi_kategori_tipe!=="income" && (
                                                <><span className="colorred">{rupiah2(data.transaksi_jumlah)}</span></>
                                              )
                                            }
                                          </div>
                                        )
                                      })
                                    : ""
                                  }
                                </>
                              )
                            }
                          </div>
                          <div className='boxdompetku1 boxlistlaporankeuangan'>
                            <div className="header">
                              <h6>Laporan Keuangan</h6>
                              {/* <span>Lihat Semua</span> */} 
                            </div>
                            <hr className='hrcustom1' />
                            {
                              jumlahdatatransaksiterakhir <= 0 && (
                                <><center>Belum ada Transaksi</center></>
                              )
                            }
                            {
                              jumlahdatatransaksiterakhir > 0 && (
                                <>
                                  <div className='row'>
                                    <div className='col-lg-6 my-2 text-center'>
                                      <h6 className='mb-3'>Pemasukan hari ini</h6>
                                      <h4 className='colorgreen'>{rupiah2(lappemasukanhariini)}</h4>
                                    </div>                            
                                    <div className="col-lg-6 my-2 text-center">
                                      <h6 className='mb-3'>Pengeluaran hari ini</h6>
                                      <h4 className='colorred'>{rupiah2(lappengeluaranhariini)}</h4>
                                    </div>
                                    <div className="col-lg-6 my-2 text-center">
                                      <h6 className='mb-3'>Pemasukan bulan ini</h6>
                                      <h4 className='colorgreen'>{rupiah2(lappemasukanbulanini)}</h4>
                                    </div>
                                    <div className="col-lg-6 my-2 text-center">
                                      <h6 className='mb-3'>Pengeluaran bulan ini</h6>
                                      <h4 className='colorred'>{rupiah2(lappengeluaranbulanini)}</h4>
                                    </div>
                                    <div className="col-lg-6 my-2 text-center">
                                      <h6 className='mb-3'>Pemasukan tahun ini</h6>
                                      <h4 className='colorgreen'>{rupiah2(lappemasukantahunini)}</h4>
                                    </div>
                                    <div className="col-lg-6 my-2 text-center">
                                      <h6 className='mb-3'>Pengeluaran tahun ini</h6>
                                      <h4 className='colorred'>{rupiah2(lappengeluarantahunini)}</h4>
                                    </div>
                                  </div>
                                </>
                              )
                            }
                          </div>

                          <div className='boxdompetku1 boxlistlaporankeuangan'>
                            <div className="header">
                              <h6>Portofolio DompetKu</h6>
                            </div>
                            <hr className='hrcustom1' />

                            {/* {chartOptions} */}
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={chartOptions}
                            />

                          </div>
                        </>
                      )
                    }

                    {/* Page Dompetku Transaksi */}
                    {
                      otherpage=="transaksi" && (
                        <>
                          <input type="search" className='boxdompetku1' placeholder='Cari tanggal, kategori atau catatan transaksi...' onChange={(e) => searchTransaksiHandler(e.target.value)} />
                          <div className='boxdompetku1 listperiode'>
                            {
                              Array.isArray(datalistperiode) ?
                              datalistperiode.map((data, i) => { 
                                var idname = data.transaksi_periode.replaceAll("/","");
                                if(periodetransaksiactive==data.transaksi_periode) {
                                  var classname = " menulistperiode menulistperiode"+idname;
                                }
                                else {
                                  if(i==0 || i=="0") { 
                                    var classname = "active menulistperiode menulistperiode"+idname; 
                                  }
                                  else {
                                    var classname = "menulistperiode menulistperiode"+idname;
                                  }
                                }
                                // active
                                return (
                                  <span key={i} onClick={(e) => changeListPeriodeTransaksi(e.currentTarget.id)} className={classname} id={idname}>{data.transaksi_periode}</span>
                                )
                              })
                              : ""
                            }
                          </div>
                          <div className='boxdompetku1 boxlisttransaksiterakhir'>
                            <div className='d-flex justify-content-between'>
                                <h6>Pemasukan</h6>
                                <span>+Rp {rupiah2(lappemasukanbulanini)}</span>
                            </div>
                            <div className='d-flex justify-content-between'>
                                <h6>Pengeluaran</h6>
                                <span>-Rp {rupiah2(lappengeluaranbulanini)}</span>
                            </div>
                            <hr/>
                            <div className='d-flex justify-content-between'>
                                {/* <h6>Total</h6> */}
                                <h6>&nbsp;</h6>
                                <span className={classlaptotal}>{laptotalpemasukanpengeluaranbulanini}</span>
                            </div>
                          </div>
                          <h5 className='font-medium'>Transaksi Saya</h5>
                          <div dangerouslySetInnerHTML={{__html: datalisttransaksitest}} />
                        </>
                      )
                    }
                    {/* End Page Dompetku Transaksi */}

                    {/* Page Dompetku Lainnya */}
                    {
                      otherpage=="lainnya" && (
                        <>
                          <hr/>
                          <h5 className='font-medium'>Menu Lainnya</h5>
                          <hr/>
                          <NavLink to={linktopagetargetbelanja} className="linklistmenulainnya menu-dompetku-targetbelanja" onClick={menudompetkutargetbelanja}>
                            <div>
                              <h5>Daftar Target Belanja Saya</h5>
                              <p>Buat Daftar Target Belanja lebih rinci di sini.</p>
                            </div>
                            <div>
                              <i className='fa fa-angle-right'></i>
                            </div>
                          </NavLink>
                          {/* <NavLink to={linktopagerangkumantransaksi} className="linklistmenulainnya">
                            <div>
                              <h5>Rangkuman Transaksi Saya</h5>
                              <p>Lihat Rangkuman Transaksi Pemasukan dan Pengeluaran di sini.</p>
                            </div>
                            <div>
                              <i className='fa fa-angle-right'></i>
                            </div>
                          </NavLink> */}
                          <span className="linklistmenulainnya" data-bs-toggle="modal" data-bs-target="#popupkalkulasikansaldo">
                            <div>
                              <h5>Kalkulasikan Saldo Saya</h5>
                              <p>Hitung Penambahan dan Pengurangan Saldo untuk dicatat sebagai Saldo saat ini.</p>
                            </div>
                            <div>
                              <i className='fa fa-angle-right'></i>
                            </div>
                          </span>
                          <div className="modal fade" id="popupkalkulasikansaldo" tabindex="-1" aria-labelledby="popupkalkulasikansaldo" aria-hidden="true">
                            <div className="modal-dialog">
                              <div className="modal-content">
                                <div className="modal-header">
                                  <h1 className="modal-title fs-5" id="popupkalkulasikansaldo">Kalkulasikan Saldo Saya</h1>
                                  <button type="button" className="btn-close close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                  <hr className='mt-0' />
                                  <h5>Penambahan Saldo</h5>
                                  <hr/>
                                  <input autoComplete="off" type="text" id='penambahan1'className='kalkukasikansaldo form-control mb-3' placeholder='Dompet...' />
                                  <input autoComplete="off" type="text" id='penambahan2'className='kalkukasikansaldo form-control mb-3' placeholder='Rekening...' />
                                  <input autoComplete="off" type="text" id='penambahan3'className='kalkukasikansaldo form-control mb-3' placeholder='Pulsa...' />
                                  <input autoComplete="off" type="text" id='penambahan4'className='kalkukasikansaldo form-control mb-3' placeholder='Investasi...' />
                                  <input autoComplete="off" type="text" id='penambahan5'className='kalkukasikansaldo form-control mb-3' placeholder='Lainnya...' />
                                  <hr/>
                                  <h5>Pengurangan Saldo</h5>
                                  <hr/>
                                  <input autoComplete="off" type="text" id='pengurangan1' className='kalkukasikansaldo form-control mb-3' placeholder='Pengeluaran...' />
                                  <input autoComplete="off" type="text" id='pengurangan2' className='kalkukasikansaldo form-control mb-3' placeholder='Lainnya...' />
                                  <hr/>
                                  <h5>Total Saldo</h5>
                                  <h5 className='totalkalkulasikansaldo'>Rp 0</h5>
                                  <hr/>
                                  <button className='kalkukasikansaldo btn btn-primary d-block w-100 text-center' >Kalkulasi</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )
                    }
                    {/* End Page Dompetku Lainnya */}
                    {/* Page Dompetku Daftar Belanja */}
                    {
                      otherpage=="targetbelanja" && (
                        <>
                          {/* Daftar Target Belanja */}
                          {/* <br/> */}
                          <div className='boxdompetku1 boxfiltertargetbelanja row d-flex align-items-center'>
                            <div className='col-lg-8 p-0'>
                              <input type="search" style={{border:"none",boxShadow:"none"}} className='form-control w-100' placeholder='Cari tanggal atau judul target belanja...' onChange={(e) => searchTargetBelanjaHandler(e.target.value)} />
                            </div>
                            <div className='col-lg-3 p-0 col-9'>
                              <select className='form-select w-100' defaultValue={'process'} onChange={(e) => statusTargetBelanjaHandler(e.target.value)}>
                                <option value="all">Semua Data</option>
                                <option value="process">Target Dalam Proses</option>
                                <option value="completed">Target Tercapai</option>
                              </select>
                            </div>
                            <div className='col-lg-1 col-3'>
                              <button className="btn btn-primary d-block w-100" data-bs-toggle="modal" data-bs-target="#popuptambahtargetbelanja"><i className='fa fa-plus-square'></i></button>
                            </div>
                          </div>

                          <div className='boxdompetku1 boxlisttransaksiterakhir'>
                            <div className='d-flex justify-content-between'>
                                <h6>Total Dalam Proses</h6>
                                <span>Rp {rupiah2(totalhargaprocess)}</span>
                            </div>
                            <div className='d-flex justify-content-between'>
                                <h6>Total Tercapai</h6>
                                <span>Rp {rupiah2(totalhargacompleted)}</span>
                            </div>
                            <hr/>
                            <div className='d-flex justify-content-between'>
                                <h6>&nbsp;</h6>
                                {/* <h6>Total Keseluruhan</h6> */}
                                <span>Rp {rupiah2(totalhargaall)}</span>
                            </div>
                          </div>
                          <h5 className='font-medium subtitletargetbelanja'>Target Belanja Saya</h5>
                          {
                            loaddatatargetbelanja<=0 && jumlahlisttargetbelanja <= 0 && (
                              <><br/><div className='alert alert-info text-center d-block w-100'>Data tidak ditemukan</div></>
                            )
                          }
                          {
                            loaddatatargetbelanja>0 && jumlahlisttargetbelanja > 0 && (
                              <>
                                {
                                  Array.isArray(currentItems) ?
                                    currentItems.map((data, i) => {
                                      var idname = data.id_target+"_"+data.target_status;
                                      var classname = "boxdompetku1 boxlisttargetbelanja"+data.id_target+" boxlisttransaksiterakhir";
                                      return (
                                        <div key={i} className={classname}>
                                          <div className="header">
                                            <h6 className="target_nama">{data.target_nama} ({data.target_jumlah}x)</h6>
                                            <span className='target_harga d-flex align-items-center'>{rupiah2(data.target_harga)}</span>
                                          </div> 
                                          <hr/> 
                                          <div className="color2 d-flex align-items-center justify-content-between">
                                            <div className='trxlist2 cursorpointer' data-bs-toggle="modal" data-bs-target="#popupedittargetbelanja" onClick={(e) => showPopupEditTargetBelanja(e.currentTarget.id)} id={data.id_target}>
                                              {/* <h6 className='color2 m-0 mb-1'>{data.target_kategori}</h6>  */}
                                              <h6 className='color2 m-0 mb-1 target_tanggal'>Target: {data.target_tanggal.replaceAll("\\'","'")}</h6> 
                                              {/* <small className='color6 targetcatatan'></small> */}
                                              {
                                                data.target_status=="process" && (
                                                  <small className='color6 targetcatatan'>Status: Dalam Proses <i className='fa fa-clock'></i></small>
                                                )
                                              }
                                              {
                                                data.target_status=="completed" && (
                                                  <>
                                                  <small className='color6 targetcatatan'>Status: Tercapai <i className='fa fa-check'></i></small>
                                                  <br/>
                                                  <small className='color6 targetcatatan'>Tercapai: {data.target_tanggal_tercapai}</small>
                                                  </>
                                                )
                                              }
                                            </div>
                                            {
                                              data.target_status=="process" && (
                                                <span id={idname} onClick={(e) => switchStatusTargetBelanja(e.currentTarget.id)} className='switchstatustargetbelanja'><i className='fa fa-check-square' ></i></span>
                                              )
                                            }
                                            {
                                              data.target_status=="completed" && (
                                                <span id={idname} onClick={(e) => switchStatusTargetBelanja(e.currentTarget.id)} className='switchstatustargetbelanja'><i className='fa fa-sync' ></i></span>
                                              )
                                            }
                                          </div>
                                        </div>
                                      )
                                    })
                                  : ""
                                }
                                <br/>
                                <center>
                                  <ReactPaginate 
                                    breakLabel="..."
                                    nextLabel="Next"
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={2}
                                    marginPagesDisplayed={1}
                                    pageCount={pageCount}
                                    previousLabel="Previous"
                                    renderOnZeroPageCount={null}
                                    containerClassName="pagination m-auto d-inline-flex"
                                    pageClassName="page-item"
                                    breakClassName='page-item'
                                    breakLinkClassName='page-link'
                                    pageLinkClassName="page-link"
                                    previousClassName="page-item"
                                    previousLinkClassName="page-link"
                                    nextClassName="page-item"
                                    nextLinkClassName="page-link"
                                    activeClassName="active"
                                    activeLinkClassName="active"
                                  />
                                </center>
                                <br/>
                                <br/>
                              </>
                            )
                          }

                          <div className="modal fade" id="popuptambahtargetbelanja" tabindex="-1" aria-labelledby="tambahtargetbelanjaLabel" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-35">
                              <div className="modal-content">
                                <form method='POST' id='formtambahtargetbelanja' onSubmit={tambahTargetBelanja}>
                                <div className="modal-header">
                                  <h1 className="modal-title fs-5" id="tambahtargetbelanjaLabel">Tambah Target Belanja</h1>
                                  <button type="button" className="btn-close close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                  {/* Nama Target */}
                                  <label className='mb-1' htmlFor="namatarget">Nama Target</label>
                                  <input required type="text" minLength="1" maxLength="255" className="namatarget form-control mb-4" id="namatarget" autoComplete='off' placeholder="....." />
                                  {/* Tanggal Target */}
                                  <label className='mb-1' htmlFor="tanggaltarget">Tanggal Target</label>
                                  <input required type="text" readOnly style={{background:"white"}} className="tanggaltarget form-control mb-4" id="tanggaltarget" autoComplete='off' placeholder="....." />
                                  {/* Jumlah Barang */}
                                  <label className='mb-1' htmlFor="jumlahbarangtarget">Jumlah Barang</label>
                                  <input required type="number" className="jumlahbarangtarget form-control mb-4" value="1" id="jumlahbarangtarget" placeholder="....." />
                                  {/* Total Harga */}
                                  <label className='mb-1' htmlFor="totalhargatarget">Total Harga</label>
                                  <input required type="text" className="totalhargatarget form-control" id="totalhargatarget" placeholder="....." />
                                </div>
                                <div className="modal-footer">
                                  <button type="submit" id='submit' className="btn btn-primary">Tambah</button>
                                </div>
                                </form>
                              </div>
                            </div>
                          </div>

                          <div className="modal fade" id="popupedittargetbelanja" tabindex="-1" aria-labelledby="edittargetbelanjaLabel" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-35">
                              <div className="modal-content">
                                <form method='POST' id='formedittargetbelanja' onSubmit={perbaruiTargetBelanja}>
                                <div className="modal-header">
                                  <h1 className="modal-title fs-5" id="edittargetbelanjaLabel">Edit Target Belanja</h1>
                                  <button type="button" className="btn-close close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                  <input required type="hidden" className="form-control mb-4" id="id_targetedit" />
                                  {/* Nama Target */}
                                  <label className='mb-1' htmlFor="namatargetedit">Nama Target</label>
                                  <input required type="text" minLength="1" maxLength="255" className="namatargetedit form-control mb-4" id="namatargetedit" autoComplete='off' placeholder="....." />
                                  {/* Tanggal Target */}
                                  <label className='mb-1' htmlFor="tanggaltargetedit">Tanggal Target</label>
                                  <input required type="text" readOnly style={{background:"white"}} className="tanggaltargetedit form-control mb-4" id="tanggaltargetedit" autoComplete='off' placeholder="....." />
                                  {/* Jumlah Barang */}
                                  <label className='mb-1' htmlFor="jumlahbarangtargetedit">Jumlah Barang</label>
                                  <input required type="number" className="jumlahbarangtargetedit form-control mb-4" id="jumlahbarangtargetedit" placeholder="....." />
                                  {/* Total Harga */}
                                  <label className='mb-1' htmlFor="totalhargatargetedit">Total Harga</label>
                                  <input required type="text" className="totalhargatargetedit form-control" id="totalhargatargetedit" placeholder="....." />
                                </div>
                                <div className="modal-footer">
                                  <button type="submit" id='submit' className="btn btn-success">Perbarui</button>
                                  <button type="button" className="hapustargetbelanja btn btn-danger" onClick={(e) => hapusTargetBelanja(e.currentTarget.id)} id="">Hapus</button>
                                </div>
                                </form>
                              </div>
                            </div>
                          </div>
                          
                        </>
                      )
                    }
                    {/* End Page Dompetku Daftar Belanja */}
                    {/* Page Dompetku Rangkuman Transaksi */}
                    {
                      otherpage=="rangkumantransaksi" && (
                        <>
                          <br/>
                          <hr/>
                          <br/>
                          <br/>
                          <br/>
                          <br/>
                          <div className='text-center'>Halaman Rangkuman Transaksi masih dalam tahap pengembangan</div>
                        </>
                      )
                    }
                    {/* End Page Dompetku Rangkuman Transaksi */}
                  

                  </div>



                  <br/>
                  <br/>
                  <br/>


                  <div className="modal fade" id="popupeditdompetsaya" tabindex="-1" aria-labelledby="popupeditdompetsayaLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-55">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h1 className="modal-title fs-5" id="popupeditdompetsayaLabel"></h1>
                          <button type="button" className="btn-close close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                          <hr/>
                          <h5 className='font-medium'>Tambah Dompet Saya</h5>
                          <hr/>
                          <form method='POST' onSubmit={tambahDompetSaya}>
                            <div className='row'>
                              <div className='col-lg-10'>
                                <input required minLength="1" maxLength="255" type="text" id='juduldompetsaya' className="formtext mb-2 form-control juduldompetsaya" placeholder='.....' value={judulDompetSayaAdd} onChange={(e) => setJudulDompetSayaAdd(e.target.value)} />
                              </div>
                              <div className='col-lg-2'>
                                <button type='submit' className='d-block w-100 btn btn-primary'>Tambah</button>
                              </div>
                            </div>
                          </form>
                          <hr/>
                          <h5 className='font-medium'>Edit/Hapus Dompet Saya</h5>
                          <hr/>
                          <div className='row'>
                            {
                              Array.isArray(datadompetsaya) ?
                              datadompetsaya.map((data, i) => {
                                var inputclass = "formtext juduldompetsayaedit juduldompetsayaedit"+data.id_dompetku+" form-control";
                                return (
                                  <div key={i}>
                                    {
                                      data.dompetku_nama == "Dompet" && (
                                        <>
                                          <div key={i} className='col-lg-12 mb-4'>
                                            <input disabled type="text" id='namadompet' className="form-control" value={data.dompetku_nama} />
                                          </div>
                                        </>
                                      )
                                    }
                                    {
                                      data.dompetku_nama !== "Dompet" && (
                                        <>
                                          <div key={i} className='col-lg-8 mb-2'>
                                            <input minLength="1" maxLength="255" type="text" id='namadompet' className={inputclass} placeholder={data.dompetku_nama} />
                                          </div>
                                          <div className='col-lg-2 col-6 mb-4'>
                                            <button type='button' className='d-block w-100 btn btn-success' id={data.id_dompetku}>Perbarui</button>
                                          </div>
                                          <div className='col-lg-2 col-6 mb-4'>
                                            <button type='button' className='d-block w-100 btn btn-danger'id={data.id_dompetku}>Hapus</button>
                                          </div>
                                        </>
                                      )
                                    }
                                  </div>
                                )
                              })
                              : ""
                            }
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Header Dompetku */}
                  <div className='header-dompetku'>
                      <NavLink activeClassName="active" className="menu-dompetku menu-dompetku-beranda" onClick={menudompetkuberanda} to={linktopagedompetku}>
                          <i className='fa fa-home'></i>
                          <span>Beranda</span>
                      </NavLink>
                      <NavLink activeClassName="active" className="menu-dompetku menu-dompetku-transaksi" onClick={menudompetkutransaksi} to={linktopagetransaksi}>
                          <i className='fa fa-wallet'></i>
                          <span>Transaksi</span>
                      </NavLink>
                      <a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a>
                      <span data-bs-toggle="modal" data-bs-target="#tambahtransaksi" className='tambahtransaksi'><i className='fa fa-plus'></i></span>
                      <NavLink activeClassName="active" className="menu-dompetku menu-dompetku-lainnya" onClick={menudompetkulainnya} to={linktopagedompetkulainnya}>
                          <i className='fa fa-list'></i>
                          <span>Lainnya</span>
                      </NavLink>
                      <NavLink activeClassName="active" className="menu-dompetku menu-dompetku-dashboard" to="/dashboard/">
                          <i className='fa fa-user-circle'></i>
                          <span>Dashboard</span>
                      </NavLink>
                  </div>
                  <div className="modal fade" id="tambahtransaksi" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                      <div className="modal-dialog modal-dialog-35">
                          <div className="modal-content">
                          <form method='POST' onSubmit={tambahTransaksi} id='tambahtransaksi'>
                              <div className="modal-header">
                                  <h1 className="modal-title fs-5" id="exampleModalLabel">Tambah Transaksi</h1>
                                  <button type="button" className="btn-close close" data-bs-dismiss="modal" aria-label="Close"></button>
                              </div>
                              <div className="modal-body">
                                  <label className='mb-1' htmlFor="jumlah">Jumlah</label>
                                  <input required type="text" className="form-control mb-4" id="jumlah" name="jumlah" autoComplete='off' placeholder="....." />
                                  <label className='mb-1' htmlFor="kategori">Kategori</label>
                                  <select required className='form-select mb-1' id='kategori'>
                                    <optgroup label="Pengeluaran">
                                      {
                                        Array.isArray(datakattransaksipengeluaran) ?
                                        datakattransaksipengeluaran.map((data, i) => {
                                          return (
                                            <option key={i} tipe="outcome" value={data.id_kategori}>{data.kategori_judul}</option>
                                          )
                                        })
                                        : ""
                                      }
                                    </optgroup>
                                    <optgroup label="Pemasukan">
                                      {
                                        Array.isArray(datakattransaksipemasukan) ?
                                        datakattransaksipemasukan.map((data, i) => {
                                          return (
                                            <option key={i} tipe="income" value={data.id_kategori}>{data.kategori_judul}</option>
                                          )
                                        })
                                        : ""
                                      }
                                    </optgroup>
                                  </select>
                                  <a style={{cursor:"pointer"}} status="hide" className='textdecorationnone font-14 color1 d-block mb-4 tambahkategoritransaksi' onClick={tambahkategoritransaksi}>Kelola Kategori <i className="fa fa-angle-down"></i></a>
                                  <div className='boxtambahkategoritransaksi' style={{display:"none"}}>
                                      <hr/>
                                      <h5 className='font-medium'>Tambah Kategori</h5>
                                      <hr/>
                                      <div className='row'>
                                        <div className='col-lg-6 col-6 mb-2'>
                                          <input className='form-control' placeholder='Kategori' id='forminputkategoritransaksiadd' />
                                        </div>
                                        <div className='col-lg-4 col-6'>
                                          <select className='form-select' id='formselecttipetrasaksiadd'>
                                            <option value="">Tipe</option>
                                            <option value="income">Pemasukan</option>
                                            <option value="outcome">Pengeluaran</option>
                                          </select>
                                        </div>
                                        <div className='col-lg-2 formtambahkategoritransaksi'>
                                          <button type='button' className='d-block w-100 btn btn-primary' onClick={tambahKategoriTransaksiHandler}><i className='fa fa-plus-square'></i></button>
                                        </div>
                                      </div>
                                      <hr/>
                                      <h5 className='font-medium'>Edit/Hapus Kategori</h5>
                                      <hr/>
                                      <div className='row'>
                                      {
                                        Array.isArray(datakattransaksi) ?
                                          datakattransaksi.map((data, i) => {
                                            var inputclass = "form-control formkategorijuduledit formkategorijuduledit"+data.id_kategori;
                                            var selectclass = "form-select formkategoritipeedit"+data.id_kategori;
                                            var classnameperbaruikategoritransaksi = "col-lg-2 col-6 mb-2 perbaruikategoritransaksi"+data.id_kategori;
                                            return (
                                              <>
                                                {
                                                  data.kategori_judul == "Pemasukan" && (
                                                    <>
                                                      <div key={i} className='col-lg-12 mb-4'>
                                                        <input disabled type="text" className="form-control" value={data.kategori_judul} />
                                                      </div>
                                                    </>
                                                  )
                                                }
                                                {
                                                  data.kategori_judul == "Pengeluaran" && (
                                                    <>
                                                      <div key={i} className='col-lg-12 mb-4'>
                                                        <input disabled type="text" className="form-control" value={data.kategori_judul} />
                                                      </div>
                                                    </>
                                                  )
                                                }

                                                {
                                                  data.kategori_judul !== "Pemasukan" && data.kategori_judul !== "Pengeluaran" && (
                                                    <>
                                                      <div key={i} className='col-lg-4 col-6 mb-2'>
                                                        <input className={inputclass} placeholder={data.kategori_judul} id='forminputkategoritransaksiedit' />
                                                      </div>
                                                      <div className='col-lg-4 col-6'>
                                                        <select className={selectclass} id='formselecttipetrasaksiedit'>
                                                          {
                                                            data.kategori_tipe=="income" && (
                                                              <>
                                                              <option value="income">Pemasukan</option>
                                                              <option value="outcome">Pengeluaran</option>
                                                              </>
                                                            )
                                                          }
                                                          {
                                                            data.kategori_tipe=="outcome" && (
                                                              <>
                                                              <option value="outcome">Pengeluaran</option>
                                                              <option value="income">Pemasukan</option>
                                                              </>
                                                            )
                                                          }
                                                        </select>
                                                      </div>
                                                      <div className={classnameperbaruikategoritransaksi}>
                                                        <button type='button' className='d-block w-100 btn btn-success' id={data.id_kategori} onClick={(e) => perbaruiKategoriTransaksiHandler(e.currentTarget.id)}><i className='fa fa-edit'></i></button>
                                                      </div>
                                                      <div className='col-lg-2 col-6 mb-4'>
                                                        <button type='button' className='d-block w-100 btn btn-danger' id={data.id_kategori} onClick={(e) => hapusKategoriTransaksiHandler(e.currentTarget.id)}><i className='fa fa-trash-alt'></i></button>
                                                      </div>
                                                    </>
                                                  )
                                                }
                                              </>
                                            )
                                          })
                                        : ""
                                      }
                                      </div>
                                      <hr/>
                                  </div>
                                  <label className='mb-1' htmlFor="tanggal">Tanggal</label>
                                  <input required type="text" readOnly style={{background:"white"}} className="tanggal form-control mb-4" id="tanggal" name="tanggal" autoComplete='off' placeholder="....." value={tanggal} onChange={(e) => settanggal(e.target.value)} />
                                  {/* <label className='mb-1' htmlFor="dompet">Dompet</label>
                                  <select required className='form-select mb-4' id='dompet' onChange={(e) => setdompet(e.target.value)}>
                                    {
                                      Array.isArray(datadompetsaya) ?
                                        datadompetsaya.map((data, i) => {
                                          return (
                                            <>
                                              <option value={data.id_dompetku}>{data.dompetku_nama}</option>
                                            </>  
                                          )
                                        })
                                      : ""
                                    }
                                  </select> */}
                                  <label className='mb-1' htmlFor="catatan">Catatan</label>
                                  <textarea rows="4" className="form-control" id="catatan" name="catatan" autoComplete='off' placeholder="....." onChange={(e) => setcatatan(e.target.value)} value={catatan}>{catatan}</textarea>
                                  
                                {/* <div className="form-check form-check-rangkumantransaksi">
                                  <input checked className="form-check-input" type="checkbox" name="flexRadioDefault" id="masukkankerangkumantransaksi" />
                                  <label style={{cursor:"pointer"}} className="form-check-label" htmlFor="masukkankerangkumantransaksi">Masukkan ke Rangkuman Pengeluaran Saya</label>
                                </div> */}

                              </div>
                              <div className="modal-footer">
                                <button type="submit" id='submit' className="btn btn-primary">Tambah</button>
                              </div>
                          </form>
                          </div>
                      </div>
                  </div>
                  {/* End Header Dompetku */}
                  
                  {/* Page Transaksi */}
                  <div className="modal fade" id="popupedittransaksi" tabindex="-1" aria-labelledby="popupedittransaksiLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-35">
                      <div className="modal-content">
                        <form method='POST' onSubmit={editTransaksi} id='edittransaksi'>
                          <div className="modal-header">
                              <h1 className="modal-title fs-5" id="exampleModalLabel">View/Edit Transaksi</h1>
                              <button type="button" className="btn-close close" data-bs-dismiss="modal" aria-label="Close"></button>
                          </div>
                          <div className="modal-body">
                              <input required type="hidden" id="id_transaksiedit" />
                              <label className='mb-1' htmlFor="jumlahedit">Jumlah</label>
                              <input required type="text" className="form-control mb-4" id="jumlahedit" name="jumlahedit" autoComplete='off' placeholder="....." />
                              <label className='mb-1' htmlFor="kategoriedit">Kategori</label>
                              <select required className='form-select mb-1' id='kategoriedit'>
                                
                              </select>
                              <a style={{cursor:"pointer"}} status="hide" className='textdecorationnone font-14 color1 d-block mb-4 tambahkategoritransaksi' onClick={tambahkategoritransaksi}>Kelola Kategori <i className="fa fa-angle-down"></i></a>
                              <div className='boxtambahkategoritransaksi' style={{display:"none"}}>
                                  <hr/>
                                  <h5 className='font-medium'>Tambah Kategori</h5>
                                  <hr/>
                                  <div className='row'>
                                    <div className='col-lg-6 col-6 mb-2'>
                                      <input className='form-control' placeholder='Kategori' id='forminputkategoritransaksiadd' />
                                    </div>
                                    <div className='col-lg-4 col-6'>
                                      <select className='form-select' id='formselecttipetrasaksiadd'>
                                        <option value="">Tipe</option>
                                        <option value="income">Pemasukan</option>
                                        <option value="outcome">Pengeluaran</option>
                                      </select>
                                    </div>
                                    <div className='col-lg-2 formtambahkategoritransaksi'>
                                      <button type='button' className='d-block w-100 btn btn-primary' onClick={tambahKategoriTransaksiHandler}><i className='fa fa-plus-square'></i></button>
                                    </div>
                                  </div>
                                  <hr/>
                                  <h5 className='font-medium'>Edit/Hapus Kategori</h5>
                                  <hr/>
                                  <div className='row'>
                                  {
                                    Array.isArray(datakattransaksi) ?
                                      datakattransaksi.map((data, i) => {
                                        var inputclass = "form-control formkategorijuduledit formkategorijuduledit"+data.id_kategori;
                                        var selectclass = "form-select formkategoritipeedit"+data.id_kategori;
                                        var classnameperbaruikategoritransaksi = "col-lg-2 col-6 mb-2 perbaruikategoritransaksi"+data.id_kategori;
                                        return (
                                          <>
                                            {
                                              data.kategori_judul == "Pemasukan" && (
                                                <>
                                                  <div key={i} className='col-lg-12 mb-4'>
                                                    <input disabled type="text" className="form-control" value={data.kategori_judul} />
                                                  </div>
                                                </>
                                              )
                                            }
                                            {
                                              data.kategori_judul == "Pengeluaran" && (
                                                <>
                                                  <div key={i} className='col-lg-12 mb-4'>
                                                    <input disabled type="text" className="form-control" value={data.kategori_judul} />
                                                  </div>
                                                </>
                                              )
                                            }

                                            {
                                              data.kategori_judul !== "Pemasukan" && data.kategori_judul !== "Pengeluaran" && (
                                                <>
                                                  <div key={i} className='col-lg-4 col-6 mb-2'>
                                                    <input className={inputclass} placeholder={data.kategori_judul} id='forminputkategoritransaksiedit' />
                                                  </div>
                                                  <div className='col-lg-4 col-6'>
                                                    <select className={selectclass} id='formselecttipetrasaksiedit'>
                                                      {
                                                        data.kategori_tipe=="income" && (
                                                          <>
                                                          <option value="income">Pemasukan</option>
                                                          <option value="outcome">Pengeluaran</option>
                                                          </>
                                                        )
                                                      }
                                                      {
                                                        data.kategori_tipe=="outcome" && (
                                                          <>
                                                          <option value="outcome">Pengeluaran</option>
                                                          <option value="income">Pemasukan</option>
                                                          </>
                                                        )
                                                      }
                                                    </select>
                                                  </div>
                                                  <div className={classnameperbaruikategoritransaksi}>
                                                    <button type='button' className='d-block w-100 btn btn-success' id={data.id_kategori} onClick={(e) => perbaruiKategoriTransaksiHandler(e.currentTarget.id)}><i className='fa fa-edit'></i></button>
                                                  </div>
                                                  <div className='col-lg-2 col-6 mb-4'>
                                                    <button type='button' className='d-block w-100 btn btn-danger' id={data.id_kategori} onClick={(e) => hapusKategoriTransaksiHandler(e.currentTarget.id)}><i className='fa fa-trash-alt'></i></button>
                                                  </div>
                                                </>
                                              )
                                            }
                                          </>
                                        )
                                      })
                                    : ""
                                  }
                                  </div>
                                  <hr/>
                              </div>

                              <label className='mb-1' htmlFor="tanggaledit">Tanggal</label>
                              <input required type="text" readOnly style={{background:"white"}} className="tanggaledit form-control mb-4" id="tanggaledit" name="tanggaledit" autoComplete='off' placeholder="....." />
                              
                              <label className='mb-1' htmlFor="catatanedit">Catatan</label>
                              <textarea rows="4" className="form-control" id="catatanedit" name="catatanedit" autoComplete='off' placeholder="....."></textarea>

                            {/* <div className="form-check form-check-rangkumantransaksi">
                              <input checked className="form-check-input" type="checkbox" name="flexRadioDefault" id="masukkankerangkumantransaksiedit" />
                              <label style={{cursor:"pointer"}} className="form-check-label" htmlFor="masukkankerangkumantransaksiedit">Masukkan ke Rangkuman Pengeluaran Saya</label>
                            </div> */}

                          </div>
                          <div className="modal-footer">
                            <button type="submit" id='submit' className="btn btn-success">Perbarui</button>
                            <button type="button" className="hapustransaksi btn btn-danger" onClick={(e) => hapusTransaksi(e.currentTarget.id)} id="">Hapus</button>
                          </div>
                      </form>
                      </div>
                    </div>
                  </div>
                  {/* End Page Transaksi */}

              </>
              )
            }
          </>
        )
      }
    </>
  )
}

export default DompetKu;